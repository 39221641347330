/* eslint-disable max-len */
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import PageLayout from "./PageLayout"
import ProtectedRoutes from "./ProtectedRoutes"

import Unauthorized from "../components/Error/Unauthorized"
import NoFleets from "../components/NoFleets/NoFleets"
import SiteAssetFaultsContainer from "../pages/AssetFaults/SiteAssetFaultsContainer"
import AssetView from "../pages/AssetView/AssetView"
import CentralSoftwareDeployment from "../pages/CentralSoftware/CentralSoftwareDeployment/CentralSoftwareDeployment"
import CentralSoftwareContainer from "../pages/CentralSoftware/CentralSoftwareUpdate/CentralSoftwareContainer"
import CentralConfigurationContainer from "../pages/Configuration/CentralConfiguration/CentralConfigurationContainer"
import ConfigurationDeployment from "../pages/Configuration/ConfigurationDeployment/ConfigurationDeployment"
import CustomerView from "../pages/CustomerView/CustomerView"
import FleetView from "../pages/FleetView/FleetView"
import FleetGroupAssetView from "../pages/FleetView/GroupBy/FleetGroupAssetView"
import Login from "../pages/Login/Login"
import UserAuth from "../pages/Login/UserAuth/UserAuth"
import Profile from "../pages/Profile/Profile"
import Reports from "../pages/Reports/Reports"
import SiteGroupAssetView from "../pages/SiteView/GroupBy/SiteGroupAssetView"
import SiteView from "../pages/SiteView/SiteView"
import SubFleetView from "../pages/SubFleetView/SubFleetView"
import UserManagement from "../pages/UserManagement/UserManagement"
import { AssetAuthElement } from "../utils/Constants/Auth/asset"
import { CommonAuthElement } from "../utils/Constants/Auth/common"
import { ConfigAuthElement } from "../utils/Constants/Auth/configuration"
import { CustomerAuthElement } from "../utils/Constants/Auth/customer"
import { FleetAuthElement } from "../utils/Constants/Auth/fleet"
import { SideNavAuthElement } from "../utils/Constants/Auth/side-navigation"
import { SiteAuthElement } from "../utils/Constants/Auth/site"
import { SoftwareAuthElement } from "../utils/Constants/Auth/software"
import { SubFleetAuthElement } from "../utils/Constants/Auth/sub-fleet"
import { UsersAuthElement } from "../utils/Constants/Auth/user"
import { ROUTE_CONFIG } from "../utils/GlobalConstants"
import { StorageKeys, getSession } from "../utils/SessionHelper/session"

const CUSTOMER = `/${ROUTE_CONFIG.CUSTOMER.routeName}/:${ROUTE_CONFIG.CUSTOMER.param}`
const SITE = `/${ROUTE_CONFIG.SITE.routeName}/:${ROUTE_CONFIG.SITE.param}`
const FLEET = `/${ROUTE_CONFIG.FLEET.routeName}/:${ROUTE_CONFIG.FLEET.param}`
const SUB_FLEET = `/${ROUTE_CONFIG.SUB_FLEET.routeName}/:${ROUTE_CONFIG.SUB_FLEET.param}`
const ASSET = `/${ROUTE_CONFIG.ASSET.routeName}/:${ROUTE_CONFIG.ASSET.param}`
const ASSET_FAULTS = `/${ROUTE_CONFIG.ASSET_FAULTS.routeName}`
const CENTRAL_CONFIG = `/${ROUTE_CONFIG.CENTRAL_CONFIG.routeName}`
const DEPLOY_CONFIG = `/${ROUTE_CONFIG.DEPLOY_CONFIG.routeName}/:${ROUTE_CONFIG.DEPLOY_CONFIG.param}`
const FLEET_ACCESS_DENIED = `/${ROUTE_CONFIG.FLEET_ACCESS_DENIED.routeName}`
const ACCESS_DENIED = `/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`
const MANAGE_USERS = `/${ROUTE_CONFIG.MANAGE_USERS.routeName}`
const LOGIN = `/${ROUTE_CONFIG.LOGIN.routeName}`
const LOGIN_CALLBACK = `/${ROUTE_CONFIG.LOGIN_CALLBACK.routeName}`
const USER = `/${ROUTE_CONFIG.USER.routeName}`
const VIEW_PROFILE = `/${ROUTE_CONFIG.VIEW_PROFILE.routeName}`
const REPORTS = `/${ROUTE_CONFIG.REPORTS.routeName}`
const SITE_MODALITY = `/${ROUTE_CONFIG.SITE_MODALITY.routeName}/:${ROUTE_CONFIG.SITE_MODALITY.param}`
const SITE_ASSET_TYPE = `/${ROUTE_CONFIG.SITE_ASSET_TYPE.routeName}/:${ROUTE_CONFIG.SITE_ASSET_TYPE.param}`
const SITE_CONNECTION = `/${ROUTE_CONFIG.SITE_CONNECTION.routeName}/:${ROUTE_CONFIG.SITE_CONNECTION.param}`
const FLEET_MODALITY = `/${ROUTE_CONFIG.FLEET_MODALITY.routeName}/:${ROUTE_CONFIG.FLEET_MODALITY.param}`
const FLEET_ASSET_TYPE = `/${ROUTE_CONFIG.FLEET_ASSET_TYPE.routeName}/:${ROUTE_CONFIG.FLEET_ASSET_TYPE.param}`
const FLEET_CONNECTION = `/${ROUTE_CONFIG.FLEET_CONNECTION.routeName}/:${ROUTE_CONFIG.FLEET_CONNECTION.param}`
const SOFTWARE_UPDATE = `/${ROUTE_CONFIG.SOFTWARE_UPDATE.routeName}`
const SOFTWARE_DEPLOYMENT = `/${ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.routeName}/:${ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.param}`

const PageRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        exact
        element={ <Navigate to={ `/${ROUTE_CONFIG.CUSTOMER.routeName}/${getSession(StorageKeys.CUSTOMER_ID)}` } /> }
      />

      { /* PROTECTED ROUTES ::::: START */ }
      <Route path={ "/" } element={ <PageLayout /> }>
        <Route
          path={ CUSTOMER }
          element={
            <ProtectedRoutes
              pageType={ CustomerAuthElement.ReadCustomerPage }
              element={ <CustomerView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE }
          element={
            <ProtectedRoutes
              pageType={ SiteAuthElement.ReadSitePage }
              element={ <SiteView /> }
            />
          }
        />
        { /* START: Group By Site - Modality */ }
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY }
          element={
            <ProtectedRoutes
              pageType={ SiteAuthElement.ReadSitePage }
              element={ <SiteGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + SITE_MODALITY + CENTRAL_CONFIG + DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_MODALITY +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_MODALITY +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_MODALITY +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Site - Modality */ }
        { /* START: Group By Site - Asset Type */ }
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE }
          element={
            <ProtectedRoutes
              pageType={ SiteAuthElement.ReadSitePage }
              element={ <SiteGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + ASSET_FAULTS + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + SITE_ASSET_TYPE + CENTRAL_CONFIG + DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_ASSET_TYPE +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_ASSET_TYPE +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_ASSET_TYPE +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Site - Asset Type */ }
        { /* START: Group By Site - Connection */ }
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION }
          element={
            <ProtectedRoutes
              pageType={ SiteAuthElement.ReadSitePage }
              element={ <SiteGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + SITE_CONNECTION + ASSET + ASSET_FAULTS + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + SITE_CONNECTION + CENTRAL_CONFIG + DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_CONNECTION +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_CONNECTION +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            SITE_CONNECTION +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Site - Connection */ }

        { /* START: Group By Fleet - Modality */ }
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY }
          element={
            <ProtectedRoutes
              pageType={ FleetAuthElement.ReadFleetPage }
              element={ <FleetGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_MODALITY +
            ASSET +
            ASSET_FAULTS +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_MODALITY +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_MODALITY +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET + CENTRAL_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_MODALITY +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_MODALITY +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Fleet - Modality */ }
        { /* START: Group By Fleet - Asset Type */ }
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE }
          element={
            <ProtectedRoutes
              pageType={ FleetAuthElement.ReadFleetPage }
              element={ <FleetGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET_FAULTS + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET + ASSET_FAULTS
          }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_ASSET_TYPE +
            ASSET +
            ASSET_FAULTS +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_ASSET_TYPE +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_ASSET_TYPE +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET + CENTRAL_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_ASSET_TYPE +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_ASSET_TYPE +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Fleet - Asset Type */ }
        { /* START: Group By Fleet - Connection */ }
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION }
          element={
            <ProtectedRoutes
              pageType={ FleetAuthElement.ReadFleetPage }
              element={ <FleetGroupAssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET_FAULTS + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET + ASSET_FAULTS
          }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_CONNECTION +
            ASSET +
            ASSET_FAULTS +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_CONNECTION +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_CONNECTION +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET + CENTRAL_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_CONNECTION +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            FLEET_CONNECTION +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        { /* END: Group By Fleet - Connection */ }

        <Route
          path={ CUSTOMER + SITE + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + ASSET_FAULTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalFaults }
              element={ <SiteAssetFaultsContainer /> }
            />
          }
        />

        <Route
          path={ CUSTOMER + SITE + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + ASSET_FAULTS + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + ASSET_FAULTS + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + CENTRAL_CONFIG + DEPLOY_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + CENTRAL_CONFIG + DEPLOY_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + SUB_FLEET + CENTRAL_CONFIG + DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + CENTRAL_CONFIG + DEPLOY_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + ASSET + CENTRAL_CONFIG + DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            SUB_FLEET +
            ASSET +
            CENTRAL_CONFIG +
          DEPLOY_CONFIG
          }
          element={
            <ProtectedRoutes
              pageType={ ConfigAuthElement.ReadCentalConfigDeployPage }
              element={ <ConfigurationDeployment /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET }
          element={
            <ProtectedRoutes
              pageType={ SubFleetAuthElement.ReadSubFleetPage }
              element={ <SubFleetView /> }
            />
          }
        />

        <Route
          path={ CUSTOMER + SITE + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + REPORTS }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentralReport }
              element={ <Reports /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET_ACCESS_DENIED }
          element={
            <ProtectedRoutes
              pageType={ CommonAuthElement.Any }
              element={ <NoFleets /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET }
          element={
            <ProtectedRoutes
              pageType={ FleetAuthElement.ReadFleetPage }
              element={ <FleetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + CENTRAL_CONFIG + DEPLOY_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + ASSET + CENTRAL_CONFIG + DEPLOY_CONFIG + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + CENTRAL_CONFIG + DEPLOY_CONFIG + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            SUB_FLEET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER +
            SITE +
            FLEET +
            SUB_FLEET +
            ASSET +
            CENTRAL_CONFIG +
            DEPLOY_CONFIG +
          ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + CENTRAL_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + CENTRAL_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + CENTRAL_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + CENTRAL_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + CENTRAL_CONFIG + ASSET }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={
            CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + CENTRAL_CONFIG + ASSET
          }
          element={
            <ProtectedRoutes
              pageType={ AssetAuthElement.ReadAssetPage }
              element={ <AssetView /> }
            />
          }
        />
        <Route
          path={ CUSTOMER + SITE + CENTRAL_CONFIG }
          element={
            <ProtectedRoutes
              pageType={ SideNavAuthElement.ReadCentalConfig }
              element={ <CentralConfigurationContainer /> }
            />
          }
        />
        <Route
          path={ USER + VIEW_PROFILE }
          element={
            <ProtectedRoutes pageType={ CommonAuthElement.Any } element={ <Profile /> } />
          }
        />
        <Route
          path={ USER + MANAGE_USERS }
          element={
            <ProtectedRoutes
              pageType={ [UsersAuthElement.ReadUser, UsersAuthElement.ReadUserGroup] }
              element={ <UserManagement /> }
            />
          }
        />
        { /* START OF  SOFTWARE UPDATE PAGE*/ }
        <Route
          path={ CUSTOMER + SITE + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SOFTWARE_UPDATE }
          element={ <ProtectedRoutes pageType={ SideNavAuthElement.ReadCentralSWDist } element={ <CentralSoftwareContainer /> } /> }
        />

        { /* END OF  SOFTWARE UPDATE PAGE*/ }

        { /* START OF  SOFTWARE UPDATE DEPLOYMENT PAGE*/ }
        <Route
          path={ CUSTOMER + SITE + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_MODALITY + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_ASSET_TYPE + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + SITE_CONNECTION + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_MODALITY + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_ASSET_TYPE + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + FLEET_CONNECTION + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />
        <Route
          path={ CUSTOMER + SITE + FLEET + SUB_FLEET + ASSET + SOFTWARE_UPDATE + SOFTWARE_DEPLOYMENT }
          element={ <ProtectedRoutes pageType={ SoftwareAuthElement.ReadCentalSoftDeployPage } element={ <CentralSoftwareDeployment /> } /> }
        />

        { /* END OF  SOFTWARE UPDATE DEPLOYMENT PAGE*/ }
        <Route path={ ACCESS_DENIED } element={ <Unauthorized /> } />
      </Route>
      <Route path={ LOGIN } element={ <Login /> } />
      <Route path={ LOGIN_CALLBACK } element={ <UserAuth /> } />
      <Route path={ "*" } element={ <div>Not Found</div> } />
    </Routes>
  )
}

export default PageRoutes
