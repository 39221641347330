/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import { DEFAULT_DATE_RANGE, getAndSetIndex, transformDropdown } from "../../pages/Reports/ReportsUtils"
import { transformConfigurations } from "../../pages/Reports/Tabs/Configuration/ConfigurationUtils"
import { transformSWPackage } from "../../pages/Reports/Tabs/SWPackage/SWPackageUtils"
import { fileSVC } from "../../services/reactQueries/filesvc"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { softwareSVC } from "../../services/reactQueries/softwaresvc"
import { useLazyQuery, useMutationWithHandlers, useQuery } from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../utils/GlobalConstants"

export const ReportsContext = createContext({})

export const ReportsProvider = ({ children }) => {
  const queryParams = useParams()
  const searchBarRef = useRef(null)
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const { data: siteData } = useQuery([fleetSVCKeys.GET_SITES], fleetGetSVC.getSites)
  const { state: carryForwardedState } = useLocation()

  const {
    startDate,
    endDate,
    modality: initialModality,
    model: initialModel,
    serialNumber,
    isFromAsset
  } = carryForwardedState || {}
  const sites = useMemo(() => siteData?.data?.sites || [], [siteData])
  const currentSite = useMemo(() => sites.find((eachSite) => eachSite.id == siteId), [sites, siteId])

  const siteUcmId = currentSite?.siteUcmId
  const siteName = currentSite?.siteAliasName

  const [state, setState] = useState({
    configurations: null,
    exportClicked: false,
    exportData: [],
    fromAsset: isFromAsset || false,
    modality: undefined,
    modalityOptions: null,
    model: [],
    modelOptions: null,
    reportsDateRange: {
      endDate: isFromAsset ? endDate : DEFAULT_DATE_RANGE.endDate,
      maxDate: DEFAULT_DATE_RANGE.maxDate,
      minDate: DEFAULT_DATE_RANGE.minDate,
      startDate: isFromAsset ? startDate : DEFAULT_DATE_RANGE.startDate
    },
    searchClose: false,
    searchError: false,
    searchInput: null,
    searchResult: null,
    sectionTab: 0,
    sortingObject: undefined,
    swPackage: null
  })

  const exportRef = useRef(null)

  const onModelDataLoadComplete = (data) => {
    const modelData = data?.data?.models.map((ele) => ele.split("|")[1].trim())
    if (state.fromAsset) {
      setState((prev) => ({ ...prev, model: [getAndSetIndex(modelData, initialModel, transformDropdown)] }))
    }
    setState((prev) => ({ ...prev, modelOptions: modelData }))
  }

  const [getModel, { isLoading: isModelLoading, isFetching: isModelFetching }] = useLazyQuery(
    [fleetSVCKeys.MODEL_VALUES_BY_SITE, siteId],
    () =>
      fleetGetSVC.getModelValuesBySiteAndModality(siteId, state.modalityOptions, [state.modality], { catchTime: 0 }),
    { onSuccess: onModelDataLoadComplete }
  )

  const onModalityDataLoadComplete = (data) => {
    const modalityData = data?.data?.modalities
    if (state.fromAsset) {
      setState((prev) => ({ ...prev, modality: getAndSetIndex(modalityData, initialModality, transformDropdown) }))
    }
    setState((prev) => ({ ...prev, modalityOptions: modalityData }))
  }

  const [getModality, { isLoading: isModalityListLoading }] = useLazyQuery(
    [fleetSVCKeys.MODALITY_VALUES_BY_SITE, siteId],
    () => fleetGetSVC.getModalityValuesBySite(siteId),
    { onSuccess: onModalityDataLoadComplete }
  )

  useEffect(() => {
    getModality()
  }, [siteUcmId])

  useEffect(() => {
    if (state.modality && state.modalityOptions.length > 0) {
      getModel()
    }
  }, [state.modalityOptions, state.modality])

  useEffect(() => {
    if (state.modelOptions && state.modelOptions.length > 0 && state.fromAsset) {
      searchBarRef.current.setValue(serialNumber)
      getConfigReportFiles({
        payload: {
          modality: state.modality,
          modalityOptions: state.modalityOptions,
          model: state.model,
          modelOptions: state.modelOptions,
          reportsDateRange: state.reportsDateRange,
          searchInput: serialNumber,
          siteId: siteUcmId
        },
        showToast: false
      })
      setState((prev) => ({ ...prev, fromAsset: false }))
    }
  }, [state.modelOptions, state.model])

  const onConfigurationMutationCompleted = (data) => {
    const { configurations } = data?.data || {}
    const configData = transformConfigurations(configurations)

    if (state.searchInput) {
      setState((prev) => ({ ...prev, searchResult: configData }))
      return
    }

    if (state.modality) {
      setState((prev) => ({ ...prev, configurations: configData }))
    }
  }

  const onSWPackageMutationCompleted = (data) => {
    const { packageInfo } = data?.data || {}
    const swPackageData = transformSWPackage(packageInfo)

    if (state.searchInput) {
      setState((prev) => ({ ...prev, searchResult: swPackageData }))
      return
    }

    if (state.modality) {
      setState((prev) => ({ ...prev, swPackage: swPackageData }))
    }
  }

  const {
    requestMutation: getConfigReportFiles,
    isError: configApiFailure,
    isLoading: configDataLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onConfigurationMutationCompleted,
    queryFn: fileSVC.getConfigReportsBySiteId
  })

  const {
    requestMutation: getSWPackageReportFiles,
    isError: swPackageApiFailure,
    isLoading: swPackageDataLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onSWPackageMutationCompleted,
    queryFn: softwareSVC.getSWPackageReportsBySiteId
  })

  const handleSearch = useCallback(
    (value, closeAction, hasError) => {
      setState((prev) => ({ ...prev, searchClose: closeAction, searchInput: value }))
      if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
        setState((prev) => ({ ...prev, searchError: false, searchResult: null }))
        const payload = {
          modality: state.modality,
          modalityOptions: state.modalityOptions,
          model: state.model,
          modelOptions: state.modelOptions,
          reportsDateRange: state.reportsDateRange,
          searchInput: value,
          siteId: siteUcmId
        }
        if (state.sectionTab === 0) {
          getConfigReportFiles({ payload, showToast: false })
        } else if (state.sectionTab === 1) {
          getSWPackageReportFiles({ payload, showToast: false })
        }
      } else {
        setState((prev) => ({ ...prev, searchError: true, searchResult: null }))
      }
    },
    [state]
  )

  const resetSearch = useCallback(() => {
    setState((prev) => ({ ...prev, searchClose: false, searchError: false, searchInput: null, searchResult: null }))
  }, [])

  const reset = useCallback(() => {
    setState((prev) => ({
      ...prev,
      configurations: null,
      modality: 0,
      model: [],
      swPackage: null
    }))
    resetSearch()
    searchBarRef.current?.reset()
  }, [resetSearch])

  const handleTabChange = useCallback(
    (_event, newValue) => {
      setState((prev) => ({ ...prev, sectionTab: newValue }))
      reset()
    },
    [reset]
  )

  const ReportsValue = useMemo(
    () => ({
      configApiFailure,
      configDataLoading,
      configurations: state.configurations,
      currentSite,
      exportClicked: state.exportClicked,
      exportData: state.exportData,
      exportRef,
      fromAsset: isFromAsset,
      getConfigReportFiles,
      getSWPackageReportFiles,
      handleSearch,
      handleTabChange,
      isModalityListLoading,
      isModelFetching,
      isModelLoading,
      modality: state.modality,
      modalityOptions: state.modalityOptions,
      model: state.model,
      modelOptions: state.modelOptions,
      reportsDateRange: state.reportsDateRange,
      resetSearch,
      searchBarRef,
      searchClose: state.searchClose,
      searchError: state.searchError,
      searchInput: state.searchInput,
      searchResult: state.searchResult,
      sectionTab: state.sectionTab,
      setConfigurations: (config) => setState((prev) => ({ ...prev, configurations: config })),
      setExportClicked: (clicked) => setState((prev) => ({ ...prev, exportClicked: clicked })),
      setExportData: (data) => setState((prev) => ({ ...prev, exportData: data })),
      setFromAsset: (asset) => setState((prev) => ({ ...prev, fromAsset: asset })),
      setModality: (mod) => setState((prev) => ({ ...prev, modality: mod })),
      setModel: (mdl) => setState((prev) => ({ ...prev, model: mdl })),
      setReportsDateRange: (range) => setState((prev) => ({ ...prev, reportsDateRange: range })),
      setSWPackage: (pkg) => setState((prev) => ({ ...prev, swPackage: pkg })),
      setSortingObject: (sorting) => setState((prev) => ({ ...prev, sortingObject: sorting })),
      siteName,
      siteUcmId,
      sortingObject: state.sortingObject,
      swPackage: state.swPackage,
      swPackageApiFailure,
      swPackageDataLoading
    }),
    [state, currentSite, configApiFailure, configDataLoading, swPackageApiFailure, swPackageDataLoading]
  )

  return <ReportsContext.Provider value={ ReportsValue }>{ children }</ReportsContext.Provider>
}

ReportsProvider.propTypes = {
  children: PropTypes.any
}

export const useReportsContext = () => {
  return useContext(ReportsContext)
}
