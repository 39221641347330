export const ACTIVITIES_STATUS = {
  canceled: {
    cancel: false,
    icon: "configCancelled",
    info: true,
    label: "canceled",
    retry: true
  },
  failed: {
    cancel: false,
    icon: "configFailed",
    info: true,
    label: "Failed",
    retry: true
  },
  inDownload: {
    cancel: true,
    icon: "configInDownload",
    info: false,
    label: "in Download",
    retry: false
  },
  inInstall: {
    cancel: true,
    icon: "configInprogress",
    info: false,
    label: "in Install",
    retry: false
  },
  rejected: {
    cancel: false,
    icon: "configRejected",
    info: true,
    label: "rejected",
    retry: true
  },
  success: {
    cancel: false,
    icon: "configSuccess",
    info: false,
    label: "success",
    retry: false
  }
}
