/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import styles from "./ConfirmDeployConfig.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import {
  getConfigTabCancelPayload,
  getConfigTabDeploymentPayload
} from "../../pages/AssetView/Configuration/ConfigurationUtils"
import { fileSVC, fileSVCKeys } from "../../services/reactQueries/filesvc"
import { fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { TABLE_ACTION } from "../../utils/Constants/AssetsConfig"
import { FILE_SERVICE_ERRORS } from "../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import { CANCEL, ICON_NAME, OK, ROUTE_CONFIG } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

const ConfirmDeployConfig = ({
  isOpen,
  onSubmit,
  popupStyles,
  heading,
  onCancel,
  warning,
  message,
  type,
  selectedAssets,
  serialNumber,
  selectedFileId,
  fileName,
  fromCentral,
  queryKey,
  isRetry,
  jobId,
  siteUcmId
}) => {
  const queryClient = useQueryClient()
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  const queryParamIds = useParams()
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const [refetchQueries, setRefetchQueries] = useState([])

  useEffect(() => {
    const refetchQueriesData = []
    if (type === TABLE_ACTION.CENTRAL_DEPLOY) {
      refetchQueriesData.push({
        id: siteId,
        key: fleetSVCKeys.ASSETS_FOR_DEPLOYMENT
      })
    } else if (type === TABLE_ACTION.DEPLOY) {
      refetchQueriesData.push(
        { id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG },
        { id: serialNumber, key: fileSVCKeys.GET_RECENT_ACTIVITIES }
      )
    }
    setRefetchQueries(refetchQueriesData)
  }, [type])

  const { t } = useTranslation(["confirmDeploy"])

  const refetchConfigFile = (key) => {
    queryClient.refetchQueries({
      exact: true,
      queryKey: key ?? queryKey,
      type: "active"
    })
  }

  const onMutationComplete = (res) => {
    let successMessage = ""
    if (type === TABLE_ACTION.CENTRAL_DEPLOY || type === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT) {
      if (selectedAssets.length > 1) {
        successMessage = `${t("centralMultipleDeploymentSuccess", {
          count: selectedAssets?.length,
          fileName
        })}`
      } else {
        successMessage = `${t("centralSingleDeploymentSuccess", {
          assetName: selectedAssets[0]?.name ?? selectedAssets[0]?.assetName,
          fileName
        })}`
      }
    } else if (type === TABLE_ACTION.DEPLOY) {
      successMessage = t("deploymentSuccess")
    }
    displaySuccessToast(successMessage || res?.message)
    if (type === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT) {
      refetchConfigFile()
      refetchConfigFile([fileSVCKeys.GET_CENTRAL_CONFIG_STATISTICS, siteUcmId])
    }
    onSubmit({ isOpen: false, isSuccess: true })
  }

  const onMutationError = (err) => {
    const errorCode = err?.data?.error?.code
    let errorMessage = t("deploymentError")
    if (errorCode === FILE_SERVICE_ERRORS.FILE_ALREADY_DELETED_EXCEPTION) {
      if (type === TABLE_ACTION.DEPLOY) refetchConfigFile()
      errorMessage = t("alreadyDeletedConfigFileError")
    }
    displayMediumErrorToast(errorMessage || err.message)
    onSubmit({ isOpen: false, isSuccess: false })
  }

  const onCancelMutationComplete = (res) => {
    displaySuccessToast(`${t("deploymentCancelRequestSuccess")}` || res?.message)
    if (type === TABLE_ACTION.CANCEL_CENTRAL_DEPLOY) {
      refetchConfigFile()
      refetchConfigFile([fileSVCKeys.GET_CENTRAL_CONFIG_STATISTICS, siteUcmId])
    }
    onSubmit({ isOpen: false, isSuccess: true })
  }

  const onCancelMutationError = (err) => {
    displayMediumErrorToast(t("deploymentError") || err.message)
    onSubmit({ isOpen: false, isSuccess: false })
  }

  const { requestMutation: deployConfiguration } = useMutationWithHandlers({
    onCompletedCallback: onMutationComplete,
    onErrorCallback: onMutationError,
    queryFn: fileSVC.deployConfig,
    refetchQueries
  })

  const { requestMutation: cancelConfiguration } = useMutationWithHandlers({
    onCompletedCallback: onCancelMutationComplete,
    onErrorCallback: onCancelMutationError,
    queryFn: fileSVC.cancelDeployment,
    refetchQueries: [{ id: serialNumber, key: fileSVCKeys.GET_RECENT_ACTIVITIES }]
  })

  const handleOnSubmit = () => {
    if (type === TABLE_ACTION.CENTRAL_DEPLOY) {
      const serialNumbers = selectedAssets?.map((asset) => asset.serialNumber)
      deployConfiguration({
        payload: getConfigTabDeploymentPayload(serialNumbers, selectedFileId),
        showToast: false
      })
    } else if (type === TABLE_ACTION.DEPLOY) {
      deployConfiguration({
        payload: getConfigTabDeploymentPayload([serialNumber], selectedFileId, fromCentral, isRetry, jobId),
        showToast: false
      })
    } else if (type === TABLE_ACTION.CANCEL_DEPLOY) {
      cancelConfiguration({
        payload: getConfigTabCancelPayload(selectedFileId, [serialNumber]),
        showToast: false
      })
    } else if (type === TABLE_ACTION.CANCEL_CENTRAL_DEPLOY) {
      const serialNumbers = selectedAssets?.map((asset) => asset.serialNumber)
      cancelConfiguration({
        payload: getConfigTabCancelPayload(selectedFileId, serialNumbers),
        showToast: false
      })
    } else if (type === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT) {
      const serialNumbers = selectedAssets?.map((asset) => asset.serialNumber)
      deployConfiguration({
        payload: getConfigTabDeploymentPayload(serialNumbers, selectedFileId, fromCentral, isRetry, jobId),
        showToast: false
      })
    }
  }

  return (
    <ConfirmationPopup
      heading={ heading }
      isOpen={ isOpen }
      onSubmit={ handleOnSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ type && CANCEL }
      popupStyles={ popupStyles }
    >
      { warning && (
        <span className={ styles.warningSection }>
          <Icon icon={ ICON_NAME.alertRed } iconStyles={ styles.warningIcon } />
          <span className={ styles.confirmText }>{ warning }</span>
        </span>
      ) }
      <span className={ styles.confirmContent }>{ message }</span>
    </ConfirmationPopup>
  )
}

ConfirmDeployConfig.propTypes = {
  fileName: PropTypes.string,
  fromCentral: PropTypes.bool,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  isRetry: PropTypes.bool,
  jobId: PropTypes.string,
  message: PropTypes.string,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  popupStyles: PropTypes.any,
  queryKey: PropTypes.array,
  selectedAssets: PropTypes.array,
  selectedFileId: PropTypes.string,
  serialNumber: PropTypes.string,
  siteUcmId: PropTypes.string,
  type: PropTypes.string,
  warning: PropTypes.string
}

export default ConfirmDeployConfig
