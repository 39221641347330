import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Icon from "../../../../../components/Icon/Icon"
import Loading from "../../../../../components/Loading/Loading"
import { useAssetViewContext } from "../../../../../contexts/assetView/assetView"
import { fileSVC } from "../../../../../services/reactQueries/filesvc"
import { TABLE_ACTION } from "../../../../../utils/Constants/AssetsConfig"
import { ReportAuthElement } from "../../../../../utils/Constants/Auth/report"
import { useQuery } from "../../../../../utils/CustomHooks/reactQuery"
import useAccess from "../../../../../utils/CustomHooks/useAccess"
import styles from "../../Configuration.module.scss"
import { recentActivitiesData } from "../../ConfigurationUtils"
import RecentActivityCard from "../RecentActivityCard/RecentActivityCard"

const RightContainer = ({ serialNumber, muted, handleRecentActivityAction, assetConfigDateRange }) => {
  const {
    assetDetails: { modality, model }
  } = useAssetViewContext()
  const { t } = useTranslation(["asset"])
  const currentURL = window.location.pathname

  const { data, isFetching } = useQuery([fileSVC.GET_RECENT_ACTIVITIES, serialNumber], () =>
    fileSVC.getRecentActivities(serialNumber)
  )

  const recentActivities = recentActivitiesData(data?.data?.recentActivities) || []
  const showReportsNavigate = useAccess(ReportAuthElement.ReadConfigReport)

  const handleAction = (action) => {
    const actionType = action?.retry ? TABLE_ACTION.DEPLOY : TABLE_ACTION?.CANCEL_DEPLOY
    const heading = action?.retry
      ? `${t("configurations.popup.retry.heading")}`
      : `${t("configurations.popup.cancel.heading")}`

    const message = action?.retry
      ? `${t("configurations.popup.retry.message", {
        fileName: action?.fileName,
        isRestoreORDeploy: action?.fromCentral ? "deployment" : "restore"
      })}`
      : `${t("configurations.popup.cancel.message", {
        fileName: action?.fileName,
        isRestoreORDeploy: action?.fromCentral ? "deployment" : "restore"
      })}`

    handleRecentActivityAction(actionType, {
      fileName: action?.fileName,
      fromCentral: action?.fromCentral,
      heading: heading,
      isOpen: true,
      isRetry: Boolean(action?.retry),
      jobId: action?.jobId || null,
      message: message,
      selectedFileId: action?.retry ? action?.fileId : action?.jobId,
      type: actionType
    })
  }

  const recentActivity = (activityData) => {
    return activityData?.length
      ? activityData?.map(
        (activity) =>
          <RecentActivityCard 
            key={ activity?.jobId } 
            activity={ activity } 
            muted={ muted } 
            handleAction={ handleAction } 
          />
      )
      : t("configurations.recentActivity.noData")
  }

  const displayRecentActivityData = (isFetching, data) => {
    return isFetching ? <Loading /> : recentActivity(data)
  }

  return (
    <>
      <div className={ styles.header }>{ t("configurations.recentActivity.header") }</div>
      <div className={ styles.content }>{ displayRecentActivityData(isFetching, recentActivities) }</div>
      <div className={ styles.rightContainerFooter }>
        <span className={ styles.navigatorContainar }>
          { showReportsNavigate && (
            <Link
              className={ recentActivities?.length ? styles.navigator : styles.disabledNavigator }
              to={ currentURL.concat("/reports") }
              state={ {
                endDate: assetConfigDateRange.endDate,
                isFromAsset: true,
                modality,
                model,
                serialNumber,
                startDate: assetConfigDateRange.reportsTabEndDate
              } }
            >
              <Icon
                icon={ recentActivities?.length ? "rightArrow" : "disabledRightArrow" }
                iconStyles={ styles.disableIcon }
              />
              <span>{ t("configurations.recentActivity.navigator") }</span>
            </Link>
          ) }
        </span>
        <div className={ styles.footerDescription }>{ t("configurations.recentActivity.footer") }</div>
      </div>
    </>
  )
}

RightContainer.propTypes = {
  assetConfigDateRange: PropTypes.object,
  handleRecentActivityAction: PropTypes.func,
  muted: PropTypes.bool,
  serialNumber: PropTypes.string
}

export default RightContainer
