import { generateUUID } from "../../../../utils/Common/common"

export const transformAssetLogs = (data) => {
  const fileData = data?.files ? data?.files : []

  const logFiles = []
  for(const logFile of fileData) {
    logFiles.push({
      id: logFile?.fileId,
      lastUpdated: logFile?.uploadDateTime,
      name: logFile?.name,
      requestType: logFile?.type,
      size: logFile?.size
    })
  }
  return logFiles
}

export const getRequestLogFilesPayload = (serialNumber) => {
  return {
    variables: {
      correlationId: generateUUID(),
      serialNumber
    }
  }
}
