/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIsFetching } from "react-query"

import GroupByAsset from "./groupByViews/GroupByAsset"
import GroupByConnection from "./groupByViews/GroupByConnection"
import GroupByFleet from "./groupByViews/GroupByFleet"
import GroupByModality from "./groupByViews/GroupByModality"
import GroupByModel from "./groupByViews/GroupByModel"
import HeadSection from "./internals/HeadSection"

import CreateUpdateLabelPopup from "../../../components/CreateUpdateLabelPopup/CreateUpdateLabelPopup"
import Error from "../../../components/Error/Error"
import FilterPopup from "../../../components/FilterPopup/FilterPopup"
import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { useSiteViewContext } from "../../../contexts/siteView/SiteView"
import { GROUP_BY_CHANGE_METHOD } from "../../../utils/Constants/SiteView"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import { COMPARE_VALUE, CREATE, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../utils/GlobalConstants"
import { StorageKeys, clearSession, getSession } from "../../../utils/SessionHelper/session"
import { transformAssets } from "../SiteUtils"
import style from "../SiteView.module.scss"

// eslint-disable-next-line react/display-name, no-empty-pattern
const FleetsSummary = forwardRef((props, ref) => {
  const {
    handleOpenMoveFleet,
    siteViewState,
    setSelectedAssets,
    setGroupByChangeMethod,
    handleSearchAssets,
    getFilterAssets,
    addDeviceMutationResults,
    assetContainerRef,
    isEdit,
    setIsEdit,
    fleetListCardRef,
    setGroupBySelectedValue,
    setSearchError,
    ṣearchInputRef
  } = useSiteViewContext()

  const { t } = useTranslation(["common"])
  const [openFilter, setOpenFilter] = useState(false)
  const [filterAPIError, setFilterAPIError] = useState(false)

  const updateFilterItem = (value) => {
    ṣearchInputRef?.current?.reset()
    const modalities = value.filter((item) => item.type === COMPARE_VALUE.modality).map((item) => item.label)
    const filterItem = value.filter((item) =>
      item.type === COMPARE_VALUE.model ? modalities.includes(item.parent.trim()) : true
    )

    const isModalityAvailable = value.filter((option) => option.type === COMPARE_VALUE.modality)
    const setFilterValue = isModalityAvailable.length ? filterItem : []

    getFilterAssets(setFilterValue)
  }

  const handleMoveFleet = () => {
    handleOpenMoveFleet(true)
  }

  const assetsList = siteViewState?.grouByAssetsData

  const assetsData = siteViewState?.groupBySelectedValue === 7 ? assetsList : []

  const tableData = useCallback(transformAssets(assetsData), [assetsData])

  const { handleSelectRow, resetSelection, handleSelectAll } = useCheckBoxSelection({
    data: tableData,
    onReset: () => setSelectedAssets([]),
    onSelectAllCallback: (data) => setSelectedAssets(data),
    onSelectRowCallback: (selectedRows) => setSelectedAssets(selectedRows)
  })

  const setIsSearch = (value, closeAction, hasError) => {
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      resetSelection()
      handleSearchAssets(value, closeAction)
      setSearchError(value, false)
    } else {
      setSearchError(value, true)
    }
  }

  useImperativeHandle(ref, () => ({
    resetCheckboxSelection() {
      assetContainerRef?.current?.resetSelection(false)
      resetSelection()
    }
  }))

  const handleNewFleetSubmitted = (res) => {
    if (res) {
      setTimeout(() => {
        fleetListCardRef?.current?.lastElementChild?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        })
      })
    }
    assetContainerRef?.current?.resetSelection()
    resetSelection()
    if (siteViewState?.groupBySelectedValue === 7) {
      ṣearchInputRef?.current?.reset()
      handleSearchAssets(null, true)
      setGroupBySelectedValue(1)
      setGroupByChangeMethod(GROUP_BY_CHANGE_METHOD.DROPDOWN)
    }
    clearSession(StorageKeys.SITE_CREATE_FLEET_REF)
  }

  const changeGroupBy = (value) => {
    if (siteViewState.groupBySelectedValue) {
      resetSelection()
      setSelectedAssets([])
    }
    if (siteViewState?.groupBySelectedValue === 7) {
      getFilterAssets([])
    }
    setGroupByChangeMethod(value)
  }

  const handleSetIsEdit = (data) => {
    const siteGroupByCreateFleetRef = getSession(StorageKeys.SITE_CREATE_FLEET_REF)
    if (siteGroupByCreateFleetRef && isEdit?.type?.action === CREATE && data?.isOpen === false) {
      assetContainerRef?.current?.resetSelection()
      resetSelection()
    }
    clearSession(StorageKeys.SITE_CREATE_FLEET_REF)
    setIsEdit(data)
  }

  const isFetchingData = useIsFetching({
    queryKey: siteViewState?.groupBySiteQueryKey
  })
  const getGroupByTemplate = () => {
    if ((isFetchingData || siteViewState?.loadingDeviceList) && siteViewState?.currPage <= 1) {
      return <Loading customStyles={ style.groupByLoader } />
    } else if (
      (siteViewState?.groupBySelectedValue === 7 && tableData.length === 0 && siteViewState?.searchInput) ||
      siteViewState?.searchError
    ) {
      return (
        <div className={ style.fleets }>
          <SearchErrorPage />{ " " }
        </div>
      )
    } else if (siteViewState?.groupBySelectedValue === 1) {
      return <GroupByFleet />
    } else if (siteViewState?.groupBySelectedValue === 2) {
      return <GroupByModality />
    } else if (siteViewState?.groupBySelectedValue === 3) {
      return <GroupByModel />
    } else if (siteViewState?.groupBySelectedValue === 6) {
      return <GroupByConnection />
    } else if (siteViewState?.groupBySelectedValue === 7) {
      return (
        <GroupByAsset
          ref={ assetContainerRef }
          tableData={ tableData }
          handleSelectAll={ handleSelectAll }
          handleSelectRow={ handleSelectRow }
        />
      )
    }
  }

  if (filterAPIError) return <Error error={ { message: t("errorInFilterOptionsApi") } } />

  return (
    <>
      <Loading isLoading={ addDeviceMutationResults?.loading } isOverladed message={ t("loadingTextTypeA") } />
      <HeadSection
        handleMoveFleet={ handleMoveFleet }
        searchResult={ siteViewState?.searchResults }
        searchError={ siteViewState?.searchError }
        setIsSearch={ setIsSearch }
        setGroupByChangeMethod={ changeGroupBy }
        fleetCount={ siteViewState?.contents?.length }
        setOpenFilter={ setOpenFilter }
        filters={ siteViewState.filterValue }
        setSelectedFilter={ updateFilterItem }
        assetsList={ tableData }
      />
      <>
        { getGroupByTemplate() }
        { openFilter && (
          <FilterPopup
            popupStyles={ style.popupStyles }
            data={ siteViewState?.siteViewFilterData }
            filters={ siteViewState.filterValue }
            customLoadingStyle={ style.contentLoading }
            onSubmit={ updateFilterItem }
            onCancel={ setOpenFilter }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
          />
        ) }
        { isEdit?.isOpen && (
          <CreateUpdateLabelPopup
            dataId={ isEdit?.type?.id }
            popupStyles={ style.popupStyle }
            isEditPopupOpen={ isEdit }
            setIsEditPopupOpen={ handleSetIsEdit }
            handleNewFleetSubmitted={ handleNewFleetSubmitted }
            ignoreRefetch={ siteViewState?.groupBySelectedValue === 7 }
          />
        ) }
      </>
    </>
  )
})

export default React.memo(FleetsSummary)
