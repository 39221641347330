/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import { PropTypes } from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Actions.module.scss"
import MoreMenu from "./MoreMenu/MoreMenu"

import Icon from "../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { ConfigAuthElement } from "../../../../utils/Constants/Auth/configuration"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../../../utils/GlobalConstants"

const Actions = ({
  tableProps: { row },
  handleRowClickActions,
  muted,
  hasSomeLockedFiles,
  hasSomeUnLockedFiles
}) => {
  const [selected, setSelected] = useState(false)
  const { t } = useTranslation(["asset"])
  const hasPromoteDemoteAccess = useAccess(
    row?.original?.promoted
      ? ConfigAuthElement.DemoteAssetConfig
      : ConfigAuthElement.PromoteAssetConfig
  )

  const hasLockUnlockAccess = useAccess(
    row?.original?.locked
      ? ConfigAuthElement.UnlockAssetConfig
      : ConfigAuthElement.LockAssetConfig
  )
  const hasLockScopeAccess = useAccess(ConfigAuthElement.LockAssetConfig)
  const hasUnlockScopeAccess = useAccess(ConfigAuthElement.UnlockAssetConfig)
  const showLockUnlockBlankDiv =
    (hasLockScopeAccess && hasSomeUnLockedFiles) ||
    (hasUnlockScopeAccess && hasSomeLockedFiles)

  const hasMenuAccess =
    ConfigAuthElement.DeployAssetConfig || ConfigAuthElement.DeleteAssetConfig

  const onClickAction = async (action) => {
    if (action === TABLE_ACTION.RESTORE) {
      handleDeploy()
    } else if (action.toLowerCase() === TABLE_ACTION.DELETE) {
      handleRowClickActions(TABLE_ACTION.DELETE, row?.original)
    }
  }

  const handleDeploy = () => {
    handleRowClickActions(TABLE_ACTION.DEPLOY, {
      fileName: row?.original?.configFile,
      heading: `${t("configurations.popup.deploy.heading")}`,
      isOpen: true,
      message: `${t("configurations.popup.deploy.message", {
        fileName: row?.original?.configFile
      })}`,
      selectedFileId: row?.original?.id,
      type: "deploy",
      warning: `${t("configurations.popup.deploy.warning")}`
    })
  }

  let lockTooltipText = ""
  if (row?.original?.locked) {
    lockTooltipText = t("configurations.tableActionTooltip.unlock")
  } else {
    lockTooltipText = t("configurations.tableActionTooltip.lock")
  }

  return (
    <div className={ styles.actionsCell }>
      { hasPromoteDemoteAccess && (
        <Tooltip
          arrow
          classes={ { tooltip: "configActionTooltip" } }
          title={
            row?.original?.promoted
              ? t("configurations.tableActionTooltip.demote")
              : t("configurations.tableActionTooltip.promote")
          }
        >
          <IconButton
            className={ styles.iconButton }
            onClick={ () =>
              handleRowClickActions(
                row?.original?.promoted
                  ? TABLE_ACTION.DEMOTE
                  : TABLE_ACTION.PROMOTE,
                row?.original
                // eslint-disable-next-line react/jsx-indent
              )
            }
          >
            <Checkbox
              size="small"
              inputProps={ {
                "aria-label":
                  row?.original?.configFile +
                  "-" +
                  `${
                    row?.original?.promoted ? "demote config" : "promote config"
                  }`
              } }
              checked={ row?.original?.promoted }
              checkedIcon={
                <Icon icon={ ICON_NAME.promote } iconStyles={ styles.icon } />
              }
              icon={ <Icon icon={ ICON_NAME.demote } iconStyles={ styles.icon } /> }
            />
          </IconButton>
        </Tooltip>
      ) }

      
      { hasLockUnlockAccess && (
        <Tooltip
          arrow
          title={ lockTooltipText }
          classes={ { tooltip: "configActionTooltip" } }
        >
          <IconButton
            className={ styles.iconButton }
            onClick={ () =>
              handleRowClickActions(TABLE_ACTION.LOCK, row?.original)
            }
          >
            <Checkbox
              size="small"
              inputProps={ {
                "aria-label":
                  row?.original?.configFile +
                  "-" +
                  `${row?.original?.locked ? "unlock config" : "lock config"}`
              } }
              checked={ row?.original?.locked }
              checkedIcon={
                <Icon icon={ ICON_NAME.lock } iconStyles={ styles.icon } />
              }
              icon={ <Icon icon={ ICON_NAME.unlock } iconStyles={ styles.icon } /> }
            />
          </IconButton>
        </Tooltip>
      ) }
      { !hasLockUnlockAccess && showLockUnlockBlankDiv && (
        <div className={ styles.emptyButtonDiv }></div>
      ) }
      { hasMenuAccess && (
        <span className="FleetMenu">
          <MoreMenu
            muted={ muted }
            selected={ selected }
            setSelected={ setSelected }
            onClick={ onClickAction }
            row={ row?.original }
          />
        </span>
      ) }
    </div>
  )
}

Actions.propTypes = {
  handleRowClickActions: PropTypes.any,
  hasSomeDemotedFiles: PropTypes.bool,
  hasSomeLockedFiles: PropTypes.bool,
  hasSomePromotedFiles: PropTypes.bool,
  hasSomeUnLockedFiles: PropTypes.bool,
  muted: PropTypes.bool,
  tableProps: PropTypes.any
}

export default Actions
