import { addDays, addMonths, subDays } from "date-fns"

import { ICON_NAME } from "../GlobalConstants"

export const LOGS_ACTIONS = {
  EXPORT: {
    icon: ICON_NAME.export,
    key: "export"
  },
  REFRESH: {
    icon: ICON_NAME.refresh,
    key: "refresh"
  },
  REQUEST_LOG_FILE: {
    icon: ICON_NAME.requestFile,
    key: "requestLogFile"
  }
}

export const ASSET_LOGS_BUTTONS = [
  {
    icon: LOGS_ACTIONS.REQUEST_LOG_FILE.icon,
    id: 1,
    name: LOGS_ACTIONS.REQUEST_LOG_FILE.key
  },
  {
    icon: LOGS_ACTIONS.REFRESH.icon,
    id: 2,
    name: LOGS_ACTIONS.REFRESH.key
  }
]

const sixMonthBehind = () => {
  const today = new Date() 
  const sixMonthsAgo = new Date(today) 
  sixMonthsAgo.setMonth(today.getMonth() - 6) 
  return sixMonthsAgo 
}

const todaysDate = new Date()

export const DEFAULT_DATE_RANGE = {
  dateInterval: 30,
  endDate: addDays(todaysDate, 0),
  fifteenDaysAgo: subDays(todaysDate, 15),
  maxDate: addDays(todaysDate, 0),
  maxDateOneYearFuture: addDays(todaysDate, 364),
  maxDateSixMonthFuture: addMonths(todaysDate, 6),
  minDate: subDays(todaysDate, 364),
  sixMonthStartDate: sixMonthBehind(),
  startDate: subDays(todaysDate, 13),
  todaysDate: addDays(todaysDate, 0)
}
