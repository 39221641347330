import i18n from "i18next"
import React from "react"

import Actions from "./Actions/Actions"

import { DATE_FORMAT } from "../../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../../utils/helper"
import margins from "../../../../../utils/styles/jsExports/marginExport.module.scss"

const CentralConfigurationColumnConfiguration = (filter, handleRowClickActions, hasAnyActionAccess, setActionClick) => [
  {
    accessorKey: "configurationDate",
    cell: ({ cell }) => <>{ cell.getValue() && formatDate(cell.getValue(), DATE_FORMAT.dateTime) }</>,
    defaultCanSort: true,
    header: i18n.t("configuration:table.headerLabels.configDate"),
    sortDesc: true,
    width: margins["marginInPercentage-per15o1"]
  },
  {
    accessorKey: "fileName",
    header: i18n.t("configuration:table.headerLabels.name"),
    width: margins["marginInPercentage-per9o8"]
  },
  {
    accessorKey: "assetName",
    header: i18n.t("configuration:table.headerLabels.assetName"),
    width: margins["marginInPercentage-per10o5"]
  },
  {
    accessorKey: "assetUser",
    header: i18n.t("configuration:table.headerLabels.configBy"),
    width: margins["marginInPercentage-per9"]
  },
  {
    accessorKey: "configType",
    header: i18n.t("configuration:table.headerLabels.configType"),
    width: margins["marginInPercentage-per10o6"]
  },
  {
    accessorKey: "description",
    header: i18n.t("configuration:table.headerLabels.description"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per17o9"]
  },
  {
    accessorKey: "actions",
    cell: ({ row }) => (
      <Actions
        filter={ filter }
        row={ row }
        handleRowClickActions={ handleRowClickActions }
        setActionClick={ setActionClick }
      />
    ),
    enableResizing: false,
    header: i18n.t("configuration:table.headerLabels.action"),
    show: hasAnyActionAccess,
    width: margins["marginInPercentage-per9"]
  }
]

export default CentralConfigurationColumnConfiguration
