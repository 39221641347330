/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import styles from "./CentralConfiguration.module.scss"
import { configFilterData, transformStatistics } from "./CentralConfigurationUtils"
import DeploymentActivities from "./DeploymentActivities/DeploymentActivities"
import PromotedConfiguration from "./PromotedConfigurations/PromotedConfiguration"

import { getAndUpdateAssetBreadcrumb } from "../../../components/Breadcrumb/BreadcrumbUtils"
import BarChart from "../../../components/ChartStatisticCard/BarChart"
import ConfigFilterPopup from "../../../components/ConfigFilterPopup/ConfigFilterPopup"
import DistributionStatisticsCard from "../../../components/DistributionStatisticsCard/DistributionStatisticsCard"
import Error from "../../../components/Error/Error"
import TabPanel from "../../../components/TabPanel/TabPanel"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import { fileSVC, fileSVCKeys } from "../../../services/reactQueries/filesvc"
import { ConfigAuthElement, DeploymentActivityAuthElement  } from "../../../utils/Constants/Auth/configuration"
import useAccess from "../../../utils/CustomHooks/useAccess"
import { CONFIG_CONTEXT_TYPE, ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../../utils/SessionHelper/session"
import { useConfigContext } from "../Context/ConfigurationContext"

const CentralConfiguration = () => {
  const queryClient = useQueryClient()
  const queryParamIds = useParams()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const { t } = useTranslation(["configuration"])
  const { t: common } = useTranslation(["common"])
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)
  const { isFilterSet } = useConfigContext()
  const [value, setValue] = React.useState(0)
  const [isFilterOpen, setIsFilterOpen] = useState(true)
  const [filterAPIError, setFilterAPIError] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState([])
  const assetId = queryParamIds[ROUTE_CONFIG.ASSET.param]

  useEffect(() => {
    getAndUpdateAssetBreadcrumb(queryClient, assetId, updateBreadcrumbData)
  }, [])

  const { data: configStatistics } = useQuery([fileSVCKeys.GET_CENTRAL_CONFIG_STATISTICS, siteUcmId], () =>
    fileSVC.getConfigStatistics(siteUcmId)
  )
  const configStatisticsData = configStatistics?.data?.statistics
  const { distributionData, modalityChartData } = transformStatistics(configStatisticsData)

  const hasPromotedConfigAccess = useAccess(ConfigAuthElement.ReadPromotedConfigTab)
  const hasDeployActivitiesAccess = useAccess(DeploymentActivityAuthElement.ReadDeploymentActivitiesTab)

  const updateFilterItem = (filters) => {
    setSelectedFilter(filters)
  }

  const tabs = [
    {
      component: (
        <PromotedConfiguration
          selectedFilter={ selectedFilter }
          isFilterOpen={ isFilterOpen }
          updateFilterItem={ updateFilterItem }
          setIsFilterOpen={ setIsFilterOpen }
        />
      ),
      hasAccess: hasPromotedConfigAccess,
      id: 1,
      label: t("centralConfigurations.tabs.promotedConfig")
    },
    {
      component: (
        <DeploymentActivities />
      ),
      hasAccess: hasDeployActivitiesAccess,
      id: 2,
      label: t("centralConfigurations.tabs.deploymentActivities")
    }
  ]

  if (filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <>
      <div className={ styles.statisticsSection }>
        { distributionData.map((eachDistribution) => (
          <DistributionStatisticsCard
            key={ eachDistribution.type }
            { ...eachDistribution }
          />
        )) }
        { modalityChartData?.map((chart) => {
          const { type, value, title } = chart
          return <BarChart key={ type } value={ value } type={ type } title = { title } />
        }) }
      </div>

      <div className={ styles.tabWrapper }>
        { isFilterSet && (
          <>
            <Tabs
              className={ styles.tabsContainer }
              value={ value }
              onChange={ (_event, newValue) => setValue(newValue) }
            >
              { tabs
                .filter((tab) => tab.hasAccess)
                .map((tab, index) => (
                  <Tab key={ tab.id } label={ tab.label } { ...a11yProps(index) } />
                )) }
            </Tabs>
            { tabs
              .filter((tab) => tab.hasAccess)
              .map((tab, index) => (
                <TabPanel
                  key={ tab.id }
                  className={ styles.tabPanel }
                  value={ value }
                  index={ index }
                >
                  { tab.component }
                </TabPanel>
              ))
            }
          </>
        ) }

        { isFilterOpen && (
          <ConfigFilterPopup
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            onSubmit={ updateFilterItem }
            setIsFilterOpen={ setIsFilterOpen }
            data={ configFilterData }
            popupStyles={ styles.overlay }
            type={ CONFIG_CONTEXT_TYPE.CENTRAL_CONFIG }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
            isModelMandatory={ true }
          />
        ) }
      </div>
    </>
  )
}

export default CentralConfiguration

const a11yProps = (index) => {
  return {
    "aria-controls": `config-tabpanel-${index}`,
    id: `config-tab-${index}`
  }
}
