import { IconButton, Tooltip } from "@mui/material"
import { addDays, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"

import DateRangePicker from "../../../../../components/DateRangePicker/DateRangePicker"
import Icon from "../../../../../components/Icon/Icon"
import SearchBar from "../../../../../components/SearchBar/SearchBar"
import { TABLE_ACTION } from "../../../../../utils/Constants/AssetsConfig"
import { SEARCH_INSTANCES } from "../../../../../utils/Constants/Search"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import styles from "../DeploymentActivities.module.scss"
import { INPROGRESS_STATUSES, RETRY_BUTTON_STATUSES } from "../DeploymentActivitiesUtils"

const HeadSection = ({
  t,
  configDateRange,
  setConfigDateRange,
  selectedAssets,
  handleClick,
  isConfirmPopUp
}) => {
  const isConfirmPopUpOpened = isConfirmPopUp?.isOpen
  const isConfirmPopUpType = isConfirmPopUp?.type
  const isHeaderAction = isConfirmPopUp?.isHeaderAction

  const [endMaxDate, setEndMaxDate] = useState(configDateRange.max)
  const [dateRange, setDateRange] = useState(configDateRange)

  const showRetryButton = selectedAssets?.every(item => 
    RETRY_BUTTON_STATUSES.includes(item?.status?.toLowerCase()) && !item?.isRemoved
  )
  const showCancelButton = selectedAssets?.every(item =>
    INPROGRESS_STATUSES.includes(item?.status?.toLowerCase())
  )

  const handleStartDateChange = (value) => {
    const startDate = new Date(value)
    const todaysDate = new Date()
    const thirtyDaysPlus = addDays(startDate, 29)
    let finalEndDate = dateRange.maxDate
    if (getDateOnlyFormat(thirtyDaysPlus) < getDateOnlyFormat(todaysDate)) {
      finalEndDate = thirtyDaysPlus
    }
    setEndMaxDate(finalEndDate)
    setDateRange((prevState) => ({
      ...prevState,
      endDate: finalEndDate
    }))
  }
  
  const getDateOnlyFormat = (date) => {
    return setMilliseconds(setSeconds(setMinutes(setHours(new Date(date), 0), 0), 0), 0)
  }

  const selectedAssetsText = () => {
    return selectedAssets.length > 1
      ? t("centralConfigurations.deploymentActivities.headSection.selectedAssetCountText", {
        count: selectedAssets?.length
      })
      : t("centralConfigurations.deploymentActivities.headSection.singleSelectedAssetCountText", {
        count: selectedAssets?.length
      })
  }

  return (
    <div className={ styles.filtersSection }>
      <div className={ styles.pageContentLeft }>
        { selectedAssets?.length > 0 && (
          <>
            <span role={ "contentinfo" } className={ styles.selectedCountText }>
              { selectedAssetsText() }
            </span>
            <div className={ styles.headerActionButtons }>
              { showRetryButton &&
                <Tooltip arrow title={ t("centralConfigurations.deploymentActivities.buttons.retry") }>
                  <IconButton
                    className={ 
                      (
                        isConfirmPopUpOpened &&
                    isHeaderAction &&
                    isConfirmPopUpType === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT
                      ) ? styles.activeButton : null
                    }
                    onClick={ () => handleClick(TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT) }
                    aria-label="RetrySelected"
                  >
                    <Icon
                      icon={ ICON_NAME.redo }
                      iconStyles={ styles.actionIcon }
                    />
                  </IconButton>
                </Tooltip>
              }
              { showCancelButton && 
                <Tooltip arrow title={ t("centralConfigurations.deploymentActivities.buttons.cancel") }>
                  <IconButton
                    className={ 
                      (
                        isConfirmPopUpOpened &&
                    isHeaderAction &&
                    isConfirmPopUpType === TABLE_ACTION.CANCEL_CENTRAL_DEPLOY
                      ) ? styles.activeButton : null
                    }
                    onClick={ () => handleClick(TABLE_ACTION.CANCEL_CENTRAL_DEPLOY, selectedAssets) }
                    aria-label="CancelSelected"
                  >
                    <Icon
                      icon={ ICON_NAME.cancelCircle }
                      iconStyles={ styles.actionIcon }
                    />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </>
        ) }
      </div>
      <div className={ styles.pageContentRight }>
        <div className={ styles.searchField }>
          <SearchBar type={ SEARCH_INSTANCES.centralConfigDeployActivities } />
        </div>
        <div className={ styles.datePicker }>
          <DateRangePicker
            minDate={ dateRange?.minDate }
            startDate={ dateRange?.startDate }
            endDate={ dateRange?.endDate }
            endMaxDate={ endMaxDate }
            onChange={ (value) => setConfigDateRange((prevState) => ({ ...prevState, ...value })) }
            startDateChange={ (value) => handleStartDateChange(value) }
          />
        </div>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  configDateRange: PropTypes.object,
  handleClick: PropTypes.func,
  isConfirmPopUp: PropTypes.object,
  selectedAssets: PropTypes.array,
  setConfigDateRange: PropTypes.func,
  t: PropTypes.any
}

export default HeadSection
