import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"

import styles from "./InlineLoading.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

// eslint-disable-next-line react/display-name
const InlineLoading = forwardRef(({ isLoading = true, customStyles }, ref) => {
  const { t } = useTranslation(["common"])

  if (!isLoading) {
    return null
  }
  return (
    <div className={ classNames(styles.paginationLoaderContainer, customStyles) } ref={ ref }>
      <Icon
        icon={ ICON_NAME.btnLoaderBlue }
        iconStyles={ styles.paginationLoader }
      />
      <span>{ t("loading") }</span>
    </div>
  )
})

InlineLoading.propTypes = {
  customStyles: PropTypes.any,
  isLoading: PropTypes.bool
}

export default InlineLoading
