import React from "react"

import CentralConfiguration from "./CentralConfiguration"

import { ConfigurationProvider } from "../Context/ConfigurationContext"

const CentralConfigurationContainer = () => {
  return (
    <ConfigurationProvider>
      <CentralConfiguration />
    </ConfigurationProvider>
  )
}

export default CentralConfigurationContainer
