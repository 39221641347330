/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./PromotedConfiguration.module.scss"
import {
  getPromoteDemoteFilePayload,
  intialState,
  transformConfigData
} from "./PromotedConfigurationUtils"

import PromoteDemotePopUp from "../../../../components/PromoteDemotePopUp/PromoteDemotePopUp"
import PromptModal from "../../../../components/PromptModal/PromptModal"
import { useToaster } from "../../../../contexts/ToastContext"
import { fileSVC } from "../../../../services/reactQueries/filesvc"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { FILE_SERVICE_ERRORS } from "../../../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  DEMOTE,
  GLOBAL_THROTTLE_TIME,
  OK,
  SEARCH_DEFAULT_MIN_CHARACTERS
} from "../../../../utils/GlobalConstants"
import { deviceORQueryFormatter, throttle } from "../../../../utils/helper"
import { StorageKeys, getSession } from "../../../../utils/SessionHelper/session"
import { DEFAULT_DATE_RANGE } from "../../../CentralSoftware/CentralSoftwareUpdate/CentralSoftwareUpdatesUtils"
import { ConfigurationProvider, useConfigContext } from "../../Context/ConfigurationContext"
import ConfigurationAssetsSummary from "../ConfigurationAssetsSummary/ConfigurationAssetsSummary"

const PromotedConfiguration = ({
  selectedFilter,
  updateFilterItem,
  isFilterOpen,
  setIsFilterOpen
}) => {
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  const { t } = useTranslation(["configuration"])
  const [promoteDemotePopUp, setPromoteDemotePopUp] = useState(intialState)
  const [searchInput, setSearchInput] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [searchClose, setSearchClose] = useState(false)
  const [promptModal, setIsPromptModal] = useState(intialState)
  const [transformedConfigData, setTransformedConfigData] = useState([])
  const [configDateRange, setConfigDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    maxDate: DEFAULT_DATE_RANGE.maxDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })
  const searchBarRef = useRef(null)
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)

  const { filter, setFilter, setIsFilterSet } = useConfigContext()

  useEffect(() => {
    setFilter(selectedFilter) 
    if (selectedFilter?.length === 0) setIsFilterOpen(true)
    else if (selectedFilter?.length > 0) getFilterConfigFiles(selectedFilter, true)
  }, [configDateRange, selectedFilter])

  const handleFilterOpen = () => {
    setIsFilterOpen(true)
  }

  const handleClearAll = () => {
    setFilter([])
    setTransformedConfigData([])
    resetSearch()
    setIsFilterSet(false)
    setIsFilterOpen(true)
  }

  const onMutationCompleted = (data) => {
    const configData = data?.data?.configurationList
    setTransformedConfigData(transformConfigData(configData))
    if (searchInput) setSearchResult(configData)
  }

  const {
    requestMutation: getConfigFiles,
    isError,
    isLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onMutationCompleted,
    queryFn: fileSVC.getConfigFilesBySiteId
  })

  const getFilterConfigFiles = (value, resetSearchField) => {
    deviceORQueryFormatter(value)
    let searchInputData = searchInput
    if (resetSearchField) {
      resetSearch()
      searchInputData = null
    }
    getConfigFiles({
      payload: {
        configDateRange,
        filterValue: value,
        searchInput: searchInputData,
        siteId: siteUcmId
      },
      showToast: false
    })
  }

  const handleRowClickActions = (actionType, payload) => {
    if (actionType === TABLE_ACTION.PROMOTE) {
      setPromoteDemotePopUp({
        ...promoteDemotePopUp,
        fileDetails: payload,
        isOpen: true,
        message: t("configurations.popup.demotePopup.heading", {
          fileName: payload.fileName,
          model: payload.model
        }),
        name: payload.fileName,
        type: DEMOTE
      })
    }
  }

  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const onPromoteDemoteMutationComplete = () => {
    const fileDetails = promoteDemotePopUp?.fileDetails || promptModal?.fileDetails
    const successMessage = t(
      `centralConfigurations.toaster.${!fileDetails?.deleted ? "demoteFile" : "deleteFile"}.success`,
      {
        configurationDate: fileDetails.configurationDate,
        fileName: fileDetails.fileName
      }
    )
    displaySuccessToast(successMessage)
    getFilterConfigFiles(selectedFilter, false)
    setPromoteDemotePopUp(intialState)
    setIsPromptModal(intialState)
  }

  const onPromoteDemoteMutationError = (err) => {
    const fileDetails = promoteDemotePopUp?.fileDetails || promptModal?.fileDetails
    const newPromoteState = !fileDetails?.isPromoted
    let errorMessage = t(`configurations.toaster.${!newPromoteState?.deleted ? "demoteFile" : "deleteFile"}.error`)
    const errorCode = err?.data?.error?.code
    if (errorCode === FILE_SERVICE_ERRORS.CONFIG_INPROGRESS_ACTIVITY_EXISTS_EXCEPTION) {
      errorMessage = t(`configurations.toaster.${"demoteFile"}.demoteInProgressCentralConfigError`)
    } else if (errorCode === FILE_SERVICE_ERRORS.FILE_ALREADY_DELETED_EXCEPTION) {
      getFilterConfigFiles(selectedFilter, false)
      errorMessage = t("configurations.toaster.alreadyDeletedConfigFileError")
    }
    displayMediumErrorToast(errorMessage)
    setPromoteDemotePopUp(intialState)
    setIsPromptModal(intialState)
  }

  const { requestMutation: promoteDemoteMutation } = useMutationWithHandlers({
    onCompletedCallback: onPromoteDemoteMutationComplete,
    onErrorCallback: onPromoteDemoteMutationError,
    queryFn: fileSVC.promoteLockActions
  })

  const handlePromoteDemoteFile = (note) => {
    const fileDetails = promoteDemotePopUp?.fileDetails || promptModal?.fileDetails
    promoteDemoteMutation({
      payload: {
        ...getPromoteDemoteFilePayload(fileDetails?.assetSerialNumber, fileDetails?.id, !fileDetails.promoted, note)
      },
      showToast: false
    })
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      setSearchResult(null)
      setSearchError(false)
      getConfigFiles({
        payload: {
          configDateRange,
          filterValue: selectedFilter,
          searchInput: value,
          siteId: siteUcmId
        }
      })
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  const resetSearch = () => {
    setSearchResult(null)
    setSearchError(false)
    setSearchInput(null)
    setSearchClose(false)
    searchBarRef.current?.reset()
  }

  return (
    <ConfigurationProvider>
      <div className={ styles.configAssets }>
        <ConfigurationAssetsSummary
          isLoading={ isLoading }
          isError={ isError }
          configData={ transformedConfigData }
          isFilterOpen={ isFilterOpen }
          throttleActions={ throttleActions }
          setSelectedFilter={ updateFilterItem }
          handleSearch={ handleSearch }
          searchInput={ searchInput }
          searchResult={ transformConfigData(searchResult) }
          searchError={ searchError }
          searchClose={ searchClose }
          searchBarRef={ searchBarRef }
          configDateRange={ configDateRange }
          setConfigDateRange={ setConfigDateRange }
          handleFilterOpen={ handleFilterOpen }
          handleClearAll={ handleClearAll }
          filter={ filter }
        />
      </div>

      <PromoteDemotePopUp
        { ...promoteDemotePopUp }
        type={ promoteDemotePopUp.type }
        isOpen={ promoteDemotePopUp.isOpen }
        handleCancel={ () => setPromoteDemotePopUp(intialState) }
        name={ promoteDemotePopUp.name }
        handleSubmit={ handlePromoteDemoteFile }
      />

      <PromptModal
        { ...promptModal }
        confirm={ OK }
        cancel={ CANCEL }
        onSubmit={ handlePromoteDemoteFile }
        onCancel={ () => setIsPromptModal({}) }
      />
    </ConfigurationProvider>
  )
}

PromotedConfiguration.propTypes = {
  isFilterOpen: PropTypes.bool,
  selectedFilter: PropTypes.array,
  setIsFilterOpen: PropTypes.func,
  updateFilterItem: PropTypes.func
}

export default PromotedConfiguration
