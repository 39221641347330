/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useIsFetching } from "react-query"

import AssetCard from "../../../../components/AssetCard/AssetCard"
import DataTable from "../../../../components/DataTable/DataTable"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { CommonAuthElement } from "../../../../utils/Constants/Auth/common"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import style from "../../SiteView.module.scss"

// eslint-disable-next-line react/display-name
const GroupByAsset = forwardRef(({ handleSelectAll, handleSelectRow, tableData }, ref) => {
  const hasSelectAccess = useAccess(CommonAuthElement.Any)
  const { siteViewState } = useSiteViewContext()
  const tableRef = useRef(null)
  const isFetchingAssets = useIsFetching({ queryKey: siteViewState?.groupBySiteQueryKey })
  const paginationLoaderRef = useRef()
  const cardRef = useRef()

  useEffect(() => {
    if (isFetchingAssets && siteViewState?.currPage > 1) {
      paginationLoaderRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [isFetchingAssets])

  useImperativeHandle(ref, () => ({
    resetSelection() {
      tableRef?.current?.toggleAll(false)
    }
  }))

  if (siteViewState?.isCardView) {
    return (
      <div
        className={ style.cardViewContainer }
        ref={ cardRef }
        data-testid={ "card-scrollContainer" }
      >
        { tableData?.map((asset) => (
          <div key={ asset.id } data-testid={ `${asset.name}-${asset.id}-card` }>
            <AssetCard
              isSelectable
              assetDetails={ asset }
              isSelected={ siteViewState?.selectedAssets.some(({ id }) => asset.id === id) }
              onChange={ handleSelectRow }
              status={ asset.status }
            />
          </div>
        )) }
      </div>
    )
  } else {
    return (
      <div className={ style.groupByAssetsTable }>
        <DataTable
          ref={ tableRef }
          displayRowColorBar
          rowHoverEffect
          highlightSelectedRows
          selectedRows={ siteViewState?.selectedAssets }
          selectableRows={ siteViewState?.contents?.length > 0 && hasSelectAccess }
          internalSelectAll
          tableData={ tableData }
          columnsData={ siteViewState?.groupByAssetsColumnsData }
          onSelectAllCallBack={ handleSelectAll }
          onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
          type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET }
          queryKey={ siteViewState?.groupBySiteQueryKey }
        />
      </div>
    )
  }
})

GroupByAsset.propTypes = {
  handleSelectAll: PropTypes.func,
  handleSelectRow: PropTypes.func,
  tableData: PropTypes.array
}

export default GroupByAsset
