import { Button, IconButton, Tooltip } from "@mui/material"
import { addDays, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import Icon from "../../../../components/Icon/Icon"
import {
  ASSET_LOGS_BUTTONS,
  CONFIG_ACTIONS
} from "../../../../utils/Constants/Logs"
import styles from "../LogFiles.module.scss"

const Header = ({
  assetLogsDateRange,
  setAssetLogsDateRange,
  handleRequestLogsFile,
  refreshConfigs,
  muted
}) => {
  const [endMaxDate, setEndMaxDate] = useState(assetLogsDateRange.max)
  const [dateRange, setDateRange] = useState(assetLogsDateRange)

  const { t } = useTranslation(["asset"])

  const handleClick = (buttonType) => {
    if (buttonType === CONFIG_ACTIONS.REFRESH.key) {
      refreshConfigs()
    } else {
      handleRequestLogsFile()
    }
  }

  const handleStartDateChange = (value) => {
    const startDate = new Date(value)
    const todaysDate = new Date()
    const twoWeekPlus = addDays(startDate, 13)
    let finalEndDate = dateRange.maxDate
    if (getDateOnlyFormat(twoWeekPlus) < getDateOnlyFormat(todaysDate)) {
      finalEndDate = twoWeekPlus
    }
    setEndMaxDate(finalEndDate)
    setDateRange((prevState) => ({
      ...prevState,
      endDate: finalEndDate
    }))
  }

  const getDateOnlyFormat = (date) => {
    return setMilliseconds(setSeconds(setMinutes(setHours(new Date(date), 0), 0), 0), 0)
  }

  return (
    <div className={ styles.buttonGroup }>
      { ASSET_LOGS_BUTTONS.map((button) => (
        <AuthGuard key={ button.id } actionType={ button.accessKey }>
          <Button
            key={ button.id }
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ muted && button.isMute  ? button.disabledIcon : button.icon } /> }
            aria-label={ t(`logs.buttons.${button.name}`) }
            onClick={ () => handleClick(button.name) }
            disabled = { muted && button.isMute }
          >
            { t(`logs.buttons.${button.name}`) }
          </Button>
        </AuthGuard>
      )) }
      <DateRangePicker
        minDate={ dateRange?.minDate }
        startDate={ dateRange?.startDate }
        endDate={ dateRange?.endDate }
        onChange={ (value) =>
          setAssetLogsDateRange((prevState) => ({ ...prevState, ...value }))
        }
        endMaxDate={ endMaxDate }
        startDateChange={ (value) => handleStartDateChange(value) }
      />
      <Tooltip
        arrow
        title={ <span>{ t("logs.tooltip") }</span> }
        classes={ { tooltip: "configActionTooltip" } }
      >
        <IconButton style={ { marginLeft: "-12px" } }>
          <Icon icon="about" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

Header.propTypes = {
  assetLogsDateRange: PropTypes.object,
  handleRequestLogsFile: PropTypes.func,
  muted: PropTypes.bool,
  refreshConfigs: PropTypes.func,
  setAssetLogsDateRange: PropTypes.func
}

export default Header
