import i18n from "i18next"
import React from "react"

import Actions from "./Actions"

import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../../../utils/Constants/DataTable"
import margins from "../../../../../utils/styles/jsExports/marginExport.module.scss"

const ConfigActivitiesColumnConfig = (handleRowClickActions, hasAnyActionAccess) => [
  {
    accessorKey: "assetName",
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.assetName"),
    width: margins["marginInPercentage-per9o8"]
  },
  {
    accessorKey: "serialNumber",
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.serialNumber"),
    width: margins["marginInPercentage-per10o5"]
  },
  {
    accessorKey: "model",
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.model"),
    width: margins["marginInPercentage-per9"]
  },
  {
    accessorKey: "subFleet",
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.subFleet"),
    width: margins["marginInPercentage-per10o6"]
  },
  {
    accessorKey: "lastUpdatedDate",
    defaultCanSort: true,
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.lastUpdated"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED,
    sortDesc: true,
    width: margins["marginInPercentage-per15o1"]
  },
  {
    accessorKey: "status",
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.status"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS,
    minSize: 100,
    size: 100,
    width: margins["marginInPercentage-per17o9"]
  },
  {
    accessorKey: "actions",
    cell: ({ row }) => (
      <Actions
        row={ row }
        handleRowClickActions={ handleRowClickActions }
      />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: i18n.t("configuration:centralConfigurations.deploymentActivities.table.headerLabels.action"),
    minSize: 50,
    show: hasAnyActionAccess,
    size: 50,
    width: margins["marginInPercentage-per9"]
  }
]

export default ConfigActivitiesColumnConfig
