/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import styles from "./ErrorPanel.module.scss"

import {
  TABLE_ERROR_MESSAGE,
  TABLE_INSTANCES
} from "../../../../utils/Constants/DataTable"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import { classNames } from "../../../../utils/styles/helper"
import Icon from "../../../Icon/Icon"

const ErrorPanel = ({ type, queryKey, customClass }) => {
  const { t } = useTranslation(["dataTable"])
  const queryClient = useQueryClient()

  const rowDetails = useCallback(
    TABLE_ERROR_MESSAGE()[type] || TABLE_ERROR_MESSAGE().DEFAULT
  )

  const handleTryAgain = (e) => {
    e.stopPropagation()
    if (queryKey) {
      queryClient.refetchQueries({
        exact: true,
        queryKey,
        type: "active"
      })
    }
  }

  return (
    <div role="row" name="noRows" className={ classNames(styles.root, customClass) }>
      <div role="cell" className="message">
        { rowDetails.MESSAGE }
      </div>
      <div role="cell">
        <Button
          variant="text"
          color="secondary"
          startIcon={ <Icon icon={ ICON_NAME.refresh } /> }
          onClick={ handleTryAgain }
          aria-label="Try again"
        >
          { t("tryAgain") }
        </Button>
      </div>
    </div>
  )
}

ErrorPanel.propTypes = {
  customClass: PropTypes.any,
  queryKey: PropTypes.array,
  type: PropTypes.oneOf(Object.keys(TABLE_INSTANCES))
}

export default ErrorPanel
