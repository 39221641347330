import PropTypes from "prop-types"
import React, { useId } from "react"
import { useTranslation } from "react-i18next"

import style from "./Loading.module.scss"

import { classNames } from "../../utils/styles/helper"

const Loading = ({ isLoading = true, message, isOverladed, customStyles, showSubMessage = true }) => {
  const id = useId()
  const { t } = useTranslation(["common"])
  if (!isLoading) {
    return null
  }
  return (
    <div
      className={ classNames(
        style.loadingContainer,
        customStyles,
        isOverladed && style.isOverladed
      ) }
    >
      <div className={ style.customLoader }></div>

      <span id={ id } className={ style.loadingText }>
        { message || "Loading..." }
      </span>
      { showSubMessage && <span className={ style.loadingDesc }>{ t("loadingMessage") }</span> }
    </div>
  )
}

Loading.propTypes = {
  customStyles: PropTypes.any,
  isLoading: PropTypes.bool,
  isOverladed: PropTypes.bool,
  message: PropTypes.string,
  showSubMessage: PropTypes.bool
}

export default Loading
