import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../../components/Icon/Icon"
import { LogFilesAuthElement } from "../../../../utils/Constants/Auth/logs"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import styles from "../LogFiles.module.scss"

const Action = ({ tableProps: { row }, handleRowClickActions }) => {
  const { t } = useTranslation(["logFiles"])
  const hasAccess = useAccess(LogFilesAuthElement.DownloadAssetLog)

  return (
    <>
      { hasAccess && (
        <Tooltip arrow title={ t("download") }>
          <span>
            <IconButton
              className={ styles.mutedActions }
              onClick={ () => handleRowClickActions(row?.original) }
              aria-label={ row?.original?.name }
            >
              <Icon icon={ ICON_NAME.downloadIcon } />
            </IconButton>
          </span>
        </Tooltip>
      ) }
    </>
  )
}

Action.propTypes = {
  handleRowClickActions: PropTypes.func,
  tableProps: PropTypes.any
}

export default Action
