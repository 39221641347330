/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import styles from "./Configuration.module.scss"
import ConfigurationTableColumnConfig from "./ConfigurationTableColumnConfig"
import {
  getDeleteFilePayload,
  getLockUnlockFilePayload,
  getPromoteDemoteFilePayload,
  getRequestConfigFilePayload,
  intialState,
  openPromptModalOnAction,
  transformDeviceConfigs
} from "./ConfigurationUtils"
import HeadSection from "./internals/HeadSection"
import RightContainer from "./internals/RightContainer/RightContainer"

import ConfirmDeployConfig from "../../../components/ConfirmDeployConfig/ConfirmDeployConfig"
import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import IconLabel from "../../../components/IconLabel/IconLabel"
import Loading from "../../../components/Loading/Loading"
import PromoteDemotePopUp from "../../../components/PromoteDemotePopUp/PromoteDemotePopUp"
import PromptModal from "../../../components/PromptModal/PromptModal"
import { useAssetViewContext } from "../../../contexts/assetView/assetView"
import { useToaster } from "../../../contexts/ToastContext"
import { fileSVC, fileSVCKeys } from "../../../services/reactQueries/filesvc"
import { DEFAULT_DATE_RANGE } from "../../../utils/Constants/AssetLogs"
import { ASSET_CONFIG_INFO, TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import { FILE_SERVICE_ERRORS } from "../../../utils/Constants/ErrorCodes"
import { useLazyQuery, useMutationWithHandlers } from "../../../utils/CustomHooks/reactQuery"
import useAccess from "../../../utils/CustomHooks/useAccess"
import {
  CANCEL,
  DEMOTE,
  GLOBAL_THROTTLE_TIME,
  OK,
  PROMOTE,
  SEARCH_DEFAULT_MIN_CHARACTERS
} from "../../../utils/GlobalConstants"
import { throttle } from "../../../utils/helper"

const Configuration = () => {
  const {
    assetDetails: { muted, serialNumber }
  } = useAssetViewContext()
  const queryClient = useQueryClient()
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  const { t } = useTranslation(["asset"])
  const tableRef = useRef(null)
  const searchBarRef = useRef(null)
  const fileDetailsRef = useRef({})
  const [isConfirmPopUp, setIsConfirmPopUp] = useState({})
  const [promptModal, setIsPromptModal] = useState({})
  const queryKey = [fileSVCKeys.GET_ASSET_CONFIG, serialNumber]
  const [promoteDemotePopUp, setPromoteDemotePopUp] = useState(intialState)
  const [searchInput, setSearchInput] = useState(null)
  const [searchClose, setSearchClose] = useState(false)
  const [searchError, setSearchError] = useState(false)
  const [searchResult, setSearchResult] = useState(null)
  const [assetConfigDateRange, setAssetConfigDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    reportsTabEndDate: DEFAULT_DATE_RANGE.fifteenDaysAgo,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  const onDataLoadCompleted = (data) => {
    if (searchInput) {
      const searchData = data?.data?.configurations
      setSearchResult(searchData)
    }
  }

  const [getConfigurations, { isLoading, data, isError, refetch }] = useLazyQuery(
    queryKey,
    () => fileSVC.getAssetConfigFiles(serialNumber, searchInput, assetConfigDateRange),
    { onSuccess: onDataLoadCompleted }
  )

  useEffect(() => {
    getConfigurations()
  }, [assetConfigDateRange])

  useEffect(() => {
    if (searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS || searchClose) {
      resetTable()
      getConfigurations()
    }
  }, [searchInput, searchClose])

  const refetchConfigFile = () => {
    queryClient.refetchQueries({
      exact: true,
      queryKey,
      type: "active"
    })
  }

  const onLockUnlockMutationComplete = () => {
    const newLockState = !promptModal?.fileDetails?.locked
    const successMessage = t(`configurations.toaster.${newLockState ? "lockFile" : "unlockFile"}.success`, {
      configurationDate: promptModal?.fileDetails?.configurationDate,
      fileName: promptModal?.fileDetails?.configFile ?? fileDetailsRef?.current?.configFile
    })
    displaySuccessToast(successMessage)
    setIsPromptModal({})
    fileDetailsRef.current = {}
  }

  const onLockUnlockMutationError = (err) => {
    const newLockState = !promptModal?.fileDetails?.locked
    let errorMessage = t(`configurations.toaster.${newLockState ? "lockFile" : "unlockFile"}.error`)
    const errorCode = err?.data?.error?.code
    if (errorCode === FILE_SERVICE_ERRORS.FILE_ALREADY_DELETED_EXCEPTION) {
      refetchConfigFile()
      errorMessage = t("configurations.toaster.alreadyDeletedConfigFileError")
    }
    displayMediumErrorToast(errorMessage)
    setIsPromptModal({})
  }

  const { requestMutation: lockUnlockMutation } = useMutationWithHandlers({
    onCompletedCallback: onLockUnlockMutationComplete,
    onErrorCallback: onLockUnlockMutationError,
    queryFn: fileSVC.promoteLockActions,
    refetchQueries: [{ id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG }]
  })

  const onPromoteDemoteMutationComplete = () => {
    const fileDetails = promoteDemotePopUp?.fileDetails
    const newPromoteState = !fileDetails?.promoted
    const successMessage = t(`configurations.toaster.${newPromoteState ? "promoteFile" : "demoteFile"}.success`, {
      configurationDate: fileDetails?.configurationDate,
      fileName: fileDetails?.configFile
    })
    displaySuccessToast(successMessage)
    setPromoteDemotePopUp(intialState)
  }

  const onPromoteDemoteMutationError = (err) => {
    const fileDetails = promoteDemotePopUp?.fileDetails
    const newPromoteState = !fileDetails?.promoted
    let errorMessage = t(`configurations.toaster.${newPromoteState ? "promoteFile" : "demoteFile"}.error`)
    const errorCode = err?.data?.error?.code
    if (errorCode === FILE_SERVICE_ERRORS.FILE_ALREADY_DELETED_EXCEPTION) {
      refetchConfigFile()
      errorMessage = t("configurations.toaster.alreadyDeletedConfigFileError")
    }
    displayMediumErrorToast(errorMessage)
    setPromoteDemotePopUp(intialState)
  }

  const { requestMutation: promoteDemoteMutation } = useMutationWithHandlers({
    onCompletedCallback: onPromoteDemoteMutationComplete,
    onErrorCallback: onPromoteDemoteMutationError,
    queryFn: fileSVC.promoteLockActions,
    refetchQueries: [{ id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG }]
  })

  const onDeleteMutationComplete = () => {
    const successMessage = t(`configurations.toaster.${"deleteFile"}.success`, {
      configurationDate: promptModal?.fileDetails?.configurationDate,
      fileName: promptModal?.fileDetails?.configFile
    })
    displaySuccessToast(successMessage)
    setIsPromptModal({})
  }

  const onDeleteMutationError = (err) => {
    let errorMessage = t(`configurations.toaster.${"deleteFile"}.error`)
    const errorCode = err?.data?.error?.code
    if (errorCode === FILE_SERVICE_ERRORS.CONFIG_INPROGRESS_ACTIVITY_EXISTS_EXCEPTION) {
      errorMessage = t(`configurations.toaster.${"deleteFile"}.deleteInProgressConfigError`)
    } else if (errorCode === FILE_SERVICE_ERRORS.FILE_ALREADY_DELETED_EXCEPTION) {
      refetchConfigFile()
      errorMessage = t("configurations.toaster.alreadyDeletedConfigFileError")
    }
    displayMediumErrorToast(errorMessage)
    setIsPromptModal({})
  }

  const { requestMutation: deleteMutation } = useMutationWithHandlers({
    onCompletedCallback: onDeleteMutationComplete,
    onErrorCallback: onDeleteMutationError,
    queryFn: fileSVC.deleteAction,
    refetchQueries: [{ id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG }]
  })

  const {
    accessKeys,
    hasSomePromotedFiles,
    hasSomeDemotedFiles,
    hasSomeLockedFiles,
    hasSomeUnLockedFiles,
    configurations
  } = useCallback(transformDeviceConfigs(data, searchInput), [data, searchInput])

  const hasAnyActionAccess = useAccess(accessKeys)

  const handleOnSubmit = (_data) => {
    setIsConfirmPopUp(_data)
  }

  const handleRowClickActions = (actionType, payload) => {
    switch (actionType) {
    case TABLE_ACTION.DEPLOY:
      setIsConfirmPopUp(payload)
      break
    case TABLE_ACTION.CANCEL_DEPLOY: 
      setIsConfirmPopUp(payload)
      break
    case TABLE_ACTION.LOCK:
      if (payload?.locked) openPromptModal(actionType, payload)
      else handleLockUnlockFile(payload)
      break
    case TABLE_ACTION.PROMOTE:
      setPromoteDemotePopUp({
        ...promoteDemotePopUp,
        fileDetails: payload,
        isOpen: true,
        name: payload.configFile,
        type: PROMOTE
      })

      break
    case TABLE_ACTION.DEMOTE:
      setPromoteDemotePopUp({
        ...promoteDemotePopUp,
        fileDetails: payload,
        isOpen: true,
        message: t("configurations.popup.promotePopup.heading", {
          fileName: payload.configFile,
          model: payload.model
        }),
        type: DEMOTE
      })
      break
    case TABLE_ACTION.DELETE:
      openPromptModal(actionType, payload)
      break
      /* istanbul ignore next */
    default:
      return null
    }
  }

  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const handleLockUnlockFile = (fileDetails) => {
    const newLockState = !fileDetails?.locked
    fileDetailsRef.current = fileDetails
    lockUnlockMutation({
      errorMessage: t(`configurations.toaster.${newLockState ? "lockFile" : "unlockFile"}.error`),
      payload: {
        ...getLockUnlockFilePayload(serialNumber, fileDetails?.id, newLockState)
      },
      successMessage: t(`configurations.toaster.${newLockState ? "lockFile" : "unlockFile"}.success`, {
        configurationDate: fileDetails.configurationDate,
        fileName: fileDetails.configFile
      })
    })
  }

  const handlePromoteDemoteFile = (note) => {
    const fileDetails = promoteDemotePopUp?.fileDetails
    const newPromoteState = !fileDetails?.promoted
    promoteDemoteMutation({
      errorMessage: t(`configurations.toaster.${newPromoteState ? "promoteFile" : "demoteFile"}.error`),
      payload: {
        ...getPromoteDemoteFilePayload(serialNumber, fileDetails?.id, newPromoteState, note)
      },
      successMessage: t(`configurations.toaster.${newPromoteState ? "promoteFile" : "demoteFile"}.success`, {
        configurationDate: fileDetails.configurationDate,
        fileName: fileDetails.configFile
      })
    })
  }

  const handleDeleteFile = (fileDetails) => {
    const newDeleteState = !fileDetails?.isDeleted
    deleteMutation({
      payload: {
        ...getDeleteFilePayload(serialNumber, fileDetails?.id, newDeleteState)
      },
      showToast: false
    })
  }

  const openPromptModal = (actionType, fileDetails) => {
    openPromptModalOnAction(actionType, fileDetails, setIsPromptModal, t)
  }

  const handelPromptSubmitCallback = () => {
    switch (promptModal.actionType) {
    case TABLE_ACTION.DELETE:
      handleDeleteFile(promptModal.fileDetails)
      break
    case TABLE_ACTION.LOCK:
      handleLockUnlockFile(promptModal.fileDetails)
      break
      /* istanbul ignore next */
    default:
      return null
    }
  }

  const { requestMutation: requestConfigFile } = useMutationWithHandlers({
    queryFn: fileSVC.requestConfig,
    refetchQueries: [{ id: serialNumber, key: fileSVCKeys.GET_ASSET_CONFIG }]
  })

  const handleRequestLogClick = () => {
    requestConfigFile({
      errorMessage: t("configurations.RequestConfigFileMessage.error"),
      payload: getRequestConfigFilePayload(serialNumber),
      successMessage: t("configurations.RequestConfigFileMessage.success")
    })
  }

  const handleRefresh = () => {
    if (searchInput || searchError) {
      resetSearch()
    } else {
      refetch()
    }
    resetTable()
  }

  const resetTable = () => {
    tableRef?.current?.toggleAll(false)
  }

  const resetSearch = () => {
    setSearchInput(null)
    setSearchResult(null)
    setSearchError(false)
    setSearchClose(true)
    searchBarRef.current?.reset()
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      setSearchResult(null)
      setSearchError(false)
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  if (isLoading)
    return (
      <div className={ styles.loader }>
        <Loading />
      </div>
    )
  if (isError) return <Error error={ { message: t("configApiError.errorMessage") } } />

  return (
    <div className={ styles.tabContainer }>
      <div className={ styles.tabLeftContainer }>
        <div className={ styles.topSection }>
          <HeadSection
            refreshConfigs={ handleRefresh }
            handleRequestLogClick={ handleRequestLogClick }
            configurations={
              searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS && searchError ? [] : configurations
            }
            handleSearch={ handleSearch }
            searchError={ searchError }
            searchResult={ searchResult }
            searchInput={ searchInput }
            searchBarRef={ searchBarRef }
            muted={ muted }
            assetConfigDateRange={ assetConfigDateRange }
            setAssetConfigDateRange={ setAssetConfigDateRange }
          />
        </div>
        <div className={ styles.tableContainer }>
          <div className={ styles.configTable }>
            <DataTable
              disableCellTooltip
              tableData={
                (searchInput && searchInput?.length < SEARCH_DEFAULT_MIN_CHARACTERS) || searchError
                  ? []
                  : configurations
              }
              type={
                !searchInput && !searchError ? TABLE_INSTANCES.ASSET_CONFIGS_INITIAL : TABLE_INSTANCES.ASSET_CONFIGS
              }
              columnsData={ ConfigurationTableColumnConfig(
                throttleActions,
                muted,
                hasAnyActionAccess,
                hasSomePromotedFiles,
                hasSomeDemotedFiles,
                hasSomeLockedFiles,
                hasSomeUnLockedFiles
              ) }
              queryKey={ queryKey }
              rowHoverEffect
              ref={ tableRef }
            />
          </div>
          { configurations?.length > 0 && (
            <div className={ styles.information }>
              { ASSET_CONFIG_INFO.map((iconLabel) => (
                <span key={ iconLabel.id }>
                  <IconLabel
                    iconName={ iconLabel.icon }
                    iconStyles={ styles.actionIcon }
                    label={ t(`configurations.labels.${iconLabel.label}`) }
                  />
                </span>
              )) }
            </div>
          ) }
        </div>
      </div>
      <div className={ styles.tabRightContainer }>
        <RightContainer 
          serialNumber={ serialNumber } 
          muted={ muted } 
          handleRecentActivityAction={ handleRowClickActions } 
          assetConfigDateRange = { assetConfigDateRange }
        />
      </div>
      <ConfirmDeployConfig
        { ...isConfirmPopUp }
        onCancel={ (value) => setIsConfirmPopUp({ value }) }
        onSubmit={ handleOnSubmit }
        popupStyles={ styles.confirmDeployPopup }
        serialNumber={ serialNumber }
        queryKey={ queryKey }
      />
      <PromptModal
        { ...promptModal }
        confirm={ OK }
        cancel={ CANCEL }
        onSubmit={ handelPromptSubmitCallback }
        onCancel={ () => setIsPromptModal({}) }
      />

      <PromoteDemotePopUp
        { ...promoteDemotePopUp }
        type={ promoteDemotePopUp.type }
        isOpen={ promoteDemotePopUp.isOpen }
        handleCancel={ () => setPromoteDemotePopUp(intialState) }
        name={ promoteDemotePopUp.name }
        handleSubmit={ handlePromoteDemoteFile }
      />
    </div>
  )
}

export default Configuration
