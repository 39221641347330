import { Checkbox, IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import styles from "./Actions.module.scss"

import AuthGuard from "../../../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../../../utils/Constants/AssetsConfig"
import { ConfigAuthElement } from "../../../../../../utils/Constants/Auth/configuration"
import {
  ICON_NAME,
  ROUTE_CONFIG
} from "../../../../../../utils/GlobalConstants"

const Actions = ({ filter, row, handleRowClickActions, setActionClick }) => {
  const { t } = useTranslation(["asset"])
  const currentURL = window.location.pathname

  const handleActionClick = () => {
    setActionClick(true)
    handleRowClickActions(TABLE_ACTION.PROMOTE, row?.original)
  }

  return (
    <div className={ styles.actionsCell }>
      <AuthGuard actionType={ ConfigAuthElement.DemoteCentralConfig }>
        <Tooltip
          arrow
          disableHoverListener={ row?.original?.deleted }
          title={
            row?.original?.promoted
              ? t("configurations.tableActionTooltip.demote")
              : t("configurations.tableActionTooltip.promote")
          }
        >
          <span>
            <IconButton
              className={ styles.iconButton }
              onClick={ handleActionClick }
              disabled={ row?.original?.deleted }
            >
              <Checkbox
                size="small"
                inputProps={ {
                  "aria-label":
                row?.original?.fileName +
                "-" +
                `${
                  row?.original?.promoted ? "demote config" : "promote config"
                }`
                } }
                checked={ row?.original?.promoted }
                checkedIcon={ <Icon icon={ ICON_NAME.promote } /> }
                icon={ <Icon icon={ ICON_NAME.demote } /> }
              />
            </IconButton>
          </span>
        </Tooltip>
      </AuthGuard>
      <AuthGuard actionType={ ConfigAuthElement.DeployCentralConfig }>
        <Tooltip
          arrow
          title={ t("configurations.centralConfigTableActionTooltip.deploy") }
        >
          <IconButton className={ styles.iconButton }>
            <Link
              to={ currentURL.concat(
                `/${ROUTE_CONFIG.DEPLOY_CONFIG.routeName}/${row?.original?.id}`
              ) }
              state={ {
                fileDetails: row?.original,
                filter: row?.original.softwareVersion
                  ? [
                    ...filter,
                    {
                      label: row?.original.softwareVersion,
                      type: "softwareVersion"
                    }
                  ]
                  : filter
              } }
            >
              <Icon icon={ ICON_NAME.deploy } />
            </Link>
          </IconButton>
        </Tooltip>
      </AuthGuard>
    </div>
  )
}

Actions.propTypes = {
  filter: PropTypes.array,
  handleRowClickActions: PropTypes.any,
  row: PropTypes.any,
  setActionClick: PropTypes.func
}

export default Actions
