import { decodeURIString, encodeURIString } from "../../../../utils/Common/common"
import { arrayGroupBy } from "../../../../utils/helper"

export const transformConfigData = (data) => {
  let configData = JSON.parse(JSON.stringify(data || []))

  let data1 = arrayGroupBy(configData, "model")

  const dataArray = []
  Object.entries(data1).forEach((item) => {
    const { configurations } = item[1][0]
    if (configurations?.length > 0) {
      const filteredConfigs = configurations?.filter((item) => item.promoted)
      filteredConfigs.forEach((item) => (item.description = decodeURIString(item.description)))
      if (filteredConfigs?.length > 0) {
        dataArray.push({
          assetName: item[0],
          assets: filteredConfigs
        })
      }
    }
  })
  return dataArray
}

export const getPromoteDemoteFilePayload = (assetSerialNumber, configFileId, promoted, note) => {
  return {
    assetSerialNumber,
    configFileId,
    variables: { description: encodeURIString(note), promoted }
  }
}

export const intialState = {
  fileDetails: "",
  isOpen: false,
  message: "",
  name: "",
  type: ""
}
