import { IconButton, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

import { ACTIVITIES_STATUS } from "./RecentActivitiesUtils"
import styles from "./RecentActiviyCard.module.scss"

import Icon from "../../../../../components/Icon/Icon"
import IconLabel from "../../../../../components/IconLabel/IconLabel"
import { ConfigAuthElement } from "../../../../../utils/Constants/Auth/configuration"
import { DATE_FORMAT } from "../../../../../utils/Constants/TimeZones"
import useAccess from "../../../../../utils/CustomHooks/useAccess"
import { formatDate } from "../../../../../utils/helper"

const RecentActivityCard = ({ activity, muted, handleAction }) => {
  const { failedStatusReason, fileName, fromCentral, isRemoved, lastUpdatedDate, status, userName } = activity

  const hasCancelAccess = useAccess(ConfigAuthElement.CancelDeployConfig) && ACTIVITIES_STATUS[status]?.cancel

  const isCancelEnabled =
    ACTIVITIES_STATUS[status]?.retry && !isRemoved
      ? { icon: "redo", toolTip: "Retry" }
      : hasCancelAccess && {
        icon: "cancelCircle",
        toolTip: "Cancel"
      }

  const isFromCentral = fromCentral ? "Deploy" : "Restore"
  const isInfoEnabled = ACTIVITIES_STATUS[status]?.info && failedStatusReason
  const isDeployVisible = useAccess(ConfigAuthElement.DeployAssetConfig || ConfigAuthElement.CancelDeployConfig)
    ? { visibility: "visible" }
    : { visibility: "hidden" }

  return (
    <div className={ styles.card }>
      <span className={ styles.cardHeader }>
        <Icon icon={ ACTIVITIES_STATUS[status]?.icon } />
        <span className={ styles.cardTitle }>
          { `${isFromCentral} configuration ${ACTIVITIES_STATUS[status]?.label}` }
        </span>
        <span className={ styles.actionButtons }>
          { isInfoEnabled && (
            <Tooltip arrow title={ failedStatusReason } className={ styles.toolTipStyle }>
              <IconButton>
                <Icon icon="info" iconStyles={ styles.iconStyles } />
              </IconButton>
            </Tooltip>
          ) }
          { Boolean(isCancelEnabled) && (
            <Tooltip arrow title={ isCancelEnabled.toolTip } className={ styles.toolTipStyle }>
              <IconButton
                style={ isDeployVisible }
                className={ muted ? styles.mutedActions : "" }
                aria-label={ `${isCancelEnabled?.toolTip}-${activity?.fileId}` }
                onClick={ () =>
                  handleAction({
                    ...activity,
                    cancel: ACTIVITIES_STATUS[status]?.cancel,
                    retry: ACTIVITIES_STATUS[status]?.retry
                  })
                }
              >
                <Icon icon={ isCancelEnabled.icon } iconStyles={ styles.iconStyles } />
              </IconButton>
            </Tooltip>
          ) }
        </span>
      </span>
      <div className={ styles.cardContent }>
        <span>{ fileName }</span>
        <span className={ styles.deployInfo }>
          <span className={ styles.userName }>
            By
            <IconLabel label={ `${userName}` } labelStyles={ styles.textEllipsis } displayTooltip={ true } />
          </span>
          { ` | ${formatDate(lastUpdatedDate, DATE_FORMAT.dateTime)}` }
        </span>
      </div>
    </div>
  )
}

RecentActivityCard.propTypes = {
  activity: PropTypes.object,
  handleAction: PropTypes.func,
  muted: PropTypes.bool
}

export default RecentActivityCard
