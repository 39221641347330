/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material"
import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router"

import { transformAssets, transformFilterData } from "./ConfigDeploymentUtils"
import styles from "./ConfigurationDeployment.module.scss"
import DeploymentAssetsSummary from "./DeploymentAssetsSummary/DeploymentAssetsSummary"

import AuthGuard from "../../../components/AuthGuard/AuthGuard"
import { getAndUpdateAssetBreadcrumb } from "../../../components/Breadcrumb/BreadcrumbUtils"
import ConfigFilterPopup from "../../../components/ConfigFilterPopup/ConfigFilterPopup"
import ConfirmDeployConfig from "../../../components/ConfirmDeployConfig/ConfirmDeployConfig"
import Error from "../../../components/Error/Error"
import Icon from "../../../components/Icon/Icon"
import { useBreadcrumbContext } from "../../../contexts/breadcrumb/breadcrumb"
import { fleetGetSVC, fleetSVCKeys } from "../../../services/reactQueries/fleetsvc"
import { CommonAuthElement } from "../../../utils/Constants/Auth/common"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { useLazyQuery, useQuery } from "../../../utils/CustomHooks/reactQuery"
import { COMPARE_VALUE, CONFIG_CONTEXT_TYPE, ICON_NAME, ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { formatDate } from "../../../utils/helper"
import { ConfigurationProvider } from "../Context/ConfigurationContext"

const ConfigurationDeployment = () => {
  let { state } = useLocation()
  const queryClient = useQueryClient()
  const { updateBreadcrumbData } = useBreadcrumbContext()
  const { t } = useTranslation(["configDeployment", "configuration"])
  const { t: common } = useTranslation(["common"])
  const assetSummaryRef = useRef(null)
  const searchInputRef = useRef(null)
  const [selectedAssets, setSelectedAssets] = useState([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(state?.filter)
  const [searchInput, setSearchInput] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [filterAPIError, setFilterAPIError] = useState(false)

  const [isConfirmPopUp, setIsConfirmPopUp] = useState({
    heading: "",
    isOpen: false,
    message: "",
    onCancel: false,
    onSubmit: false,
    type: ""
  })

  const queryParamIds = useParams()
  const navigate = useNavigate()

  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]
  const assetId = queryParamIds[ROUTE_CONFIG.ASSET.param]

  useEffect(() => {
    getAndUpdateAssetBreadcrumb(queryClient, assetId, updateBreadcrumbData)
    const modelFilterItem = selectedFilters?.filter((item) => item.type === COMPARE_VALUE.model)
    if (!modelFilterItem || modelFilterItem.length === 0) navigate(-1)
  }, [assetId, selectedFilters])

  const [getAssets, { data, isFetching, isError }] = useLazyQuery([fleetSVCKeys.ASSETS_FOR_DEPLOYMENT, siteId], () =>
    fleetGetSVC.getAssetsForConfigFileDeployment(siteId, selectedFilters, searchInput)
  )

  useEffect(() => {
    getAssets()
  }, [searchInput, selectedFilters, searchError])

  const fileDetails = state?.fileDetails
  const assets = useMemo(() => transformAssets(data?.data?.assets), [data?.data?.assets])

  const { data: swVerions } = useQuery([fleetSVCKeys.GET_SW_VERSION_BY_SITE_AND_MODALITY], () =>
    fleetGetSVC.getSWVersionsBySiteAndModality(siteId, selectedFilters?.[0].label)
  )
  const swVerionsList = swVerions && swVerions?.data?.softwareVersions

  const ConfigDeploymentFilterData = transformFilterData(swVerionsList)

  const handleDeployClick = () => {
    setIsConfirmPopUp({
      fileName: fileDetails.fileName,
      heading: t("confirmPopup.heading"),
      isOpen: true,
      message: t("confirmPopup.message", { count: selectedAssets.length }),
      selectedAssets: selectedAssets,
      selectedFileId: fileDetails?.id,
      type: "centralConfigDeployment",
      warning: t("confirmPopup.warning")
    })
  }

  const onSubmitFilters = (newFilters) => {
    setSearchInput(null)
    setSearchError(null)
    searchInputRef?.current?.reset()
    setSelectedFilters(newFilters)
  }

  const onConfirmSubmit = () => {
    setIsConfirmPopUp({ isOpen: false })
    assetSummaryRef?.current?.resetCheckboxSelection()
  }

  const getSelectedConfigDate = () => {
    return formatDate(fileDetails?.configurationDate, DATE_FORMAT.dateTime)
  }

  if (isError) {
    return <Error error={ { message: t("deploymentAssetListAPIFail") } } />
  }

  if (filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <ConfigurationProvider value={ { filter: state?.filter } }>
      <div className={ styles.configHeading }>
        <span className={ styles.configTitle }>
          <IconButton className={ styles.backButton } aria-label="Back button" onClick={ () => navigate(-1) }>
            <Icon icon={ ICON_NAME.backBlack } />
          </IconButton>
          { fileDetails?.fileName }: { getSelectedConfigDate() }
        </span>
        <span className={ styles.deployButtonCont }>
          <AuthGuard actionType={ CommonAuthElement.Any }>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <Icon
                  icon={ selectedAssets.length > 0 ? ICON_NAME.deploymentExport : ICON_NAME.deploymentExportDisabled }
                />
              }
              onClick={ handleDeployClick }
              disabled={ selectedAssets.length === 0 }
            >
              { t("buttons.deploy") }
            </Button>
          </AuthGuard>
        </span>
      </div>
      <div className={ styles.configFleets }>
        <DeploymentAssetsSummary
          ref={ assetSummaryRef }
          isFetching={ isFetching }
          searchInputRef={ searchInputRef }
          assets={ assets }
          fileDetails={ fileDetails }
          setSelectedAssets={ setSelectedAssets }
          setIsFilterOpen={ setIsFilterOpen }
          stateCarryForward={ state }
          searchInput={ searchInput }
          setSearchInput={ setSearchInput }
          searchError={ searchError }
          setSearchError={ setSearchError }
          setSelectedFilters={ onSubmitFilters }
        />

        { isFilterOpen && (
          <ConfigFilterPopup
            onSubmit={ onSubmitFilters }
            isFullScreenOverlay
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            setIsFilterOpen={ setIsFilterOpen }
            data={ ConfigDeploymentFilterData }
            popupStyles={ styles.overlay }
            stateCarryForward={ state }
            type={ CONFIG_CONTEXT_TYPE.CENTRAL_CONFIG }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
            isModelMandatory={ true }
          />
        ) }
      </div>

      <ConfirmDeployConfig
        onSubmit={ onConfirmSubmit }
        onCancel={ (value) => setIsConfirmPopUp({ isOpen: value }) }
        popupStyles={ styles.confirmationPopUp }
        { ...isConfirmPopUp }
      />
    </ConfigurationProvider>
  )
}

ConfigurationDeployment.propTypes = {
  selectedFilterItem: PropTypes.any
}

export default ConfigurationDeployment
