import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../../../components/Icon/Icon"
import { TABLE_ACTION } from "../../../../../utils/Constants/AssetsConfig"
import { DeploymentActivityAuthElement } from "../../../../../utils/Constants/Auth/configuration"
import useAccess from "../../../../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import styles from "../DeploymentActivities.module.scss"
import { INPROGRESS_STATUSES, RETRY_BUTTON_STATUSES } from "../DeploymentActivitiesUtils"

const Actions = ({ row, handleRowClickActions }) => {
  const { t } = useTranslation(["configuration"])
  const hasRetryAccess = useAccess(DeploymentActivityAuthElement.CentralRetryDeployment)
  const hasCancelAccess = useAccess(DeploymentActivityAuthElement.CentralCancelDeployment)
  
  const showRetryButton = RETRY_BUTTON_STATUSES.includes(row?.original?.status?.toLowerCase())
                          && hasRetryAccess && !row?.original?.isRemoved
  const showCancelButton = INPROGRESS_STATUSES.includes(row?.original?.status?.toLowerCase()) && hasCancelAccess

  return (
    <div className={ styles.actionsCell }>
      { showRetryButton && 
        <Tooltip arrow title={ t("centralConfigurations.deploymentActivities.buttons.retry") }>
          <IconButton
            aria-label={ row?.original?.id + "-retry" }
            onClick={ () => handleRowClickActions(TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT, [row?.original]) }
          >
            <Icon icon={ ICON_NAME.redo } iconStyles={ styles.actionIcon }/>
          </IconButton>
        </Tooltip>
      }
      { showCancelButton && 
        <Tooltip arrow title={ t("centralConfigurations.deploymentActivities.buttons.cancel") }>
          <IconButton
            aria-label={ row?.original?.id + "-cancel" }
            onClick={ () => handleRowClickActions(TABLE_ACTION.CANCEL_CENTRAL_DEPLOY, [row?.original]) }
          >
            <Icon icon={ ICON_NAME.cancelCircle } iconStyles={ styles.actionIcon } />
          </IconButton>
        </Tooltip>
      }
    </div>
  )
}

Actions.propTypes = {
  handleRowClickActions: PropTypes.any,
  row: PropTypes.any
}

export default Actions
