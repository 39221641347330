import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useId, useRef, useState } from "react"

import styles from "./ReadOnlyField.module.scss"

import { classNames } from "../../utils/styles/helper"

const ReadOnlyField = ({ label, value, labelStyles, valueStyles, showLabelInTooltip = true }) => {
  const id = useId()
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const viewElementRef = useRef(null)

  const compareSize = () => {
    const compare =
      viewElementRef?.current?.scrollWidth >
      viewElementRef?.current?.clientWidth
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    setTimeout(() => {
      compareSize()
    }, 0)
  }, [value])

  const view = (
    <span
      aria-labelledby={ id }
      className={ classNames(styles.fieldValue, valueStyles) }
      ref={ viewElementRef }
    >
      { value }
    </span>
  )

  const tooltipView = (
    <>
      { label && showLabelInTooltip && `${label} : ` }
      { value }
    </>
  )
  return (
    <>
      { label && (
        <label id={ id } className={ classNames(styles.fieldLabel, labelStyles) }>
          { label }
        </label>
      ) }
      <Tooltip
        title={ <span className={ "tooltipTitle" }>{ tooltipView }</span> }
        interactive="true"
        arrow
        disableHoverListener={ !showInfoTooltip }
      >
        { view }
      </Tooltip>
    </>
  )
}

ReadOnlyField.propTypes = {
  label: PropTypes.string,
  labelStyles: PropTypes.any,
  showLabelInTooltip: PropTypes.bool,
  value: PropTypes.any,
  valueStyles: PropTypes.any
}

export default ReadOnlyField
