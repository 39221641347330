import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"

export const transformDropdown = (data) => {
  const options = []
  data?.map((item, index) => {
    options?.push({ id: index + 1, label: item })
  })
  return options
}

export const getAndSetIndex = (dataArr, selOption, func) =>
  func(dataArr)?.find((option) => option?.label === selOption)?.id

const todaysDate = new Date()

export const DEFAULT_DATE_RANGE = {
  dateInterval: 30,
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  maxDateOneYearFuture: addDays(todaysDate, 729),
  minDate: subDays(todaysDate, 729),
  startDate: subDays(todaysDate, 364)
}
